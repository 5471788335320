
export const tagsToTableConvert = ( data ) =>{
    let tagsTotable = [];
    let item = null;
    for ( var i = 0; i < data.length; i++ ) {
        if ( data[i].parent.length === 0 ) {
            item = {
                id:data[i].id,
                name:data[i].name,
            }
            tagsTotable.push( item );
        } else if( data[i].parent.length > 0 ) {
            for ( var j = 0; j < data[i].parent.length; j++ ) {
                item = {
                    id:data[i].id,
                    name:data[i].name,
                    parentId:data[i].parent[j].id,
                }
                tagsTotable.push( item );
            }
        }
    }
    return tagsTotable;
}
