import React, { Component } from 'react';
import Table from "./Table";
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import AddModal  from './AddModal';
import UpdateModal  from './UpdateModal';

class Tag extends Component {

    state = {
        data: [],
        error: null,
        isLoaded: false,
        openAdd: false,
        name: null,
        openUpdate: false,
        errorModal: null,
        idTag: null,
        pagination: { },
        switch: true,
        tagsOptions: [],
        tags:[],
        checked:[],
    }

    componentDidMount() {
        this.getTags();
    }

    tagsToTableConvert = ( data ) =>{
        let tagsTotable = [];
        let item = null;
        for ( var i = 0; i < data.length; i++ ) {
            if ( data[i].parent.length === 0 ) {
                item = {
                    id:data[i].id,
                    name:data[i].name,
                }
                tagsTotable.push( item );
            } else if( data[i].parent.length > 0 ) {
                for ( var j = 0; j < data[i].parent.length; j++ ) {
                    item = {
                        id:data[i].id,
                        name:data[i].name,
                        parentId:data[i].parent[j].id,
                    }
                    tagsTotable.push( item );
                }
            }
        }
        return tagsTotable;
    }

    getTags = ( ) => {
        instance.get('tag')
        .then( res => {
            const data = this.tagsToTableConvert( res.data );
            this.setState({ data:data, isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    getTagsParent = ( filter ) => {
        if ( filter.length >= 2 ) {
            instance.get( 'tag?filter='+filter )
            .then( res => {
                this.setState({ tagsOptions:res.data , isLoaded:true });
            })
            .catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
        } else {
            this.setState({ tagsOptions:[] , isLoaded:true });
        }
    }

    handleChange = event => {
        this.setState({
            [ event.target.id ]: event.target.value
        });
    }

    /**
        Metodo a cargo de actualizar el rol con sus permisos asociados
    **/
    handleSubmitUpdate = ( event ) => {
        const tagData = {
            name:event.name,
            tags:event.tags,
        };
        const path = 'tag/'+ this.state.idTag;
        instance.put( path, tagData )
            .then( res => {
                this.setState({
                    idTag: null,
                    openUpdate:null,
                    name:null,
                    errorModal:"Tag modificado correctamente",
                    tags:[],
                    switch:true,
                    checked:[],
                });
                this.getTags();
            })
            .catch( err => {
                if ( err.response.status === 400 ) {
                    err = "ERROR, El Tag ya existe o es demasiado largo"
                }
                this.setState({ error:err, openUpdate:null,switch:true, name:null });
        });
    }


    /**
        Metodo a cargo de crear el rol con sus permisos asociados
    **/
    handleSubmitAdd = ( event ) => {
        const TagData = {
            name: event.name,
            tags:event.tags,
        };

        instance.post('tag/', TagData)
            .then( res => {
                this.setState({
                    errorModal:"Tags creado correctamente",
                    openAdd:null,
                    name:null,
                    switch:true,
                });
                this.getTags();
            })
            .catch( err => {
                if ( err.response.status === 400 ) {
                    err = "ERROR, El Tag ya existe o es demasiado largo"
                }
                this.setState({ errorModal:err });
        });
    }


    /**
        Metodo a cargo de cerrar el modal
    **/
    handleClose = () => {
        this.setState({
            errorModal:null,
            openUpdate: false,
            openAdd: false,
            name:null,
            switch:true,
            checked:[],
        });
    }

    /**
        Metodo encargado de activar el modal de Agregar
     **/
    onAddItem = () => {
        this.setState({ errorModal: null, openAdd: true, switch:true });
    }

    /**
        Metodo encargado de eliminar un elemento o muchos en el backend
     **/
    onDelete = ( idArray ) => {
        for (var i = 0; i < idArray.length; i++) {
            instance.delete('tag/'+ idArray[i].id)
                .then( res => {
                    this.setState({ checked:[], errorModal:"Tag/s eliminado/s correctamente" });
                    this.getTags();
                })
                .catch( err => {
                    process.env.NODE_ENV !== 'production' && console.log( err );
                });
        }
    }

    onUpdate = ( idArray ) => {
        const promises = [];
        promises.push( instance.get('/tag/' + idArray[0].id ) );
        Promise.all( promises ).then( res => {
            const resTags = res[0].data.supertags_id;
            let tags = resTags.map( tag => ({ id: tag.id , value: tag.name, label: tag.name }) );
                    this.setState({
                        tags:tags,
                        name: res[0].data.tag.name,
                        idTag: res[0].data.tag.id,
                    },  () => { this.setState({ openUpdate: true, errorModal:null })});
                    return;
        });
    }

    handleChangeSwitch = () => {
        this.state.switch ? this.setState({ switch: false }) : this.setState({ switch: true });
    }

    handleCheckBox = ( e ) => {
        let uniqueRow = [];
        for (let i = 0; i < e.length; i++) {
            if( uniqueRow.indexOf( e[i] ) === -1){
                uniqueRow.push( e[i] );
            }
        }
        this.setState({ checked:uniqueRow });
    };

    render () {
        const roles = this.props.roles;
        const { error,
                data,
                name,
                isLoaded,
                openAdd,
                errorModal,
                openUpdate,
                pagination,
                tagsOptions,
                tags,
             } = this.state;
        const switchFather=this.state.switch;

        if (!isLoaded) {
            return <div> <Spinner /></div>
        } else if(error){
            return <div><h1>{error.message}</h1></div>
        } else {
            return <div>
                <Table
                    data={data}
                    errorModal={errorModal}
                    canCreate={roles.includes('tag.create')}
                    canDelete={roles.includes('tag.delete')}
                    canTag={roles.includes('notice.tag')}
                    pagination={pagination}
                    onUpdate={this.onUpdate}
                    onAddItem={this.onAddItem}
                    onDelete={this.onDelete}
                    handleAllCheckBox={this.handleAllCheckBox}
                    handleCheckBox={this.handleCheckBox}
                    checked={this.state.checked}
                />
                {openAdd &&
                    <AddModal
                        handleClose={this.handleClose}
                        handleSubmitAdd={this.handleSubmitAdd}
                        handleChangeSwitch={this.handleChangeSwitch}
                        getTags={this.getTags}
                        errorModal={errorModal}
                        switchFather={switchFather}
                        name={name}
                        data={data}
                        tags={tags}
                        tagsOptions={tagsOptions}
                        getTagsParent={this.getTagsParent}
                    />
                }
                {openUpdate &&
                    <UpdateModal
                        handleClose={this.handleClose}
                        handleChangeSwitch={this.handleChangeSwitch}
                        errorModal={errorModal}
                        switchFather={switchFather}
                        data={data}
                        tagsOptions={tagsOptions}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                        name={name}
                        tags={tags}
                        getTags={this.getTags}
                        getTagsParent={this.getTagsParent}
                    />
                }
            </div>
        }
    }
}

export default Tag;
