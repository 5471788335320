import React, { Component } from 'react';
import Table from "../../components/Table/Table";
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import AddModal  from './AddModal';
import UpdateModal  from './UpdateModal';
import { handleAllCheckBoxGeneric, handleCheckBoxGeneric, handleChangePageGeneric } from '../TableFunctions.js';

class Source extends Component {

    state = {
        data: [],
        error: null,
        isLoaded: false,
        name: null,
        nomenclature: null,
        web_page:null,
        openAdd: false,
        openUpdate: false,
        errorModal: null,
        idSource: null,
        pagination: { },
        cleanSelection: false,
        selected: [],
        selectAll: false,
    }


    componentDidMount() {
        this.getSource();
    }

    getSource = ( page=1 ) => {
        instance.get('source?page='+page)
        .then( res => {
            const { total, current_page, per_page, data } = res.data;
            var genericData = data.map( item => {
                return { first: item.id, second: item.name, third: item.nomenclature,
            fourth: item.web_page, };
            });
            this.setState({ data:genericData, pagination: { total, currentPage: current_page, rowsPerPage: per_page }, isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    handleChange = event => {
        this.setState({
            [ event.target.id ]: event.target.value
        });
    }

    onUpdate = ( idArray ) => {
        const promises = [];
        promises.push( this.getSourceId( idArray[0] ) );
        Promise.all( promises ).then( res => {
            for (let i = 0; i < this.state.data.length; i++) {
                if ((this.state.data[i].first)===idArray[0]) {
                    this.setState({
                        cleanSelection: false,
                        errorModal: null,
                        name: this.state.data[i].second,
                        idSource: idArray[0]
                    },  () => { this.setState({openUpdate: true })});
                    return;
                }
            }
        });
    }

    /**
        Metodo a cargo de traer los permisos asociados a el rol
    **/
    getSourceId = (id) => {
        instance.get('/source/'+ id)
        .then( res => {
            this.setState({
                name:res.data.name,
                nomenclature: res.data.nomenclature,
                idSource:res.data.id,
                web_page:res.data.web_page});
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }

    /**
        Metodo a cargo de actualizar el rol con sus permisos asociados
    **/
    handleSubmitUpdate = ( event ) => {
        event.persist();

        const sourceData = {
            name: this.state.name,
            nomenclature: this.state.nomenclature,
            web_page: this.state.web_page,
        };

        const path = 'source/'+ this.state.idSource;
        instance.put(path, sourceData)
            .then( res => {
                this.setState({
                    idUser: null,
                    openUpdate:null,
                    name:null,
                    nomenclature:null,
                    web_page:null,
                    cleanSelection: true,
                    errorModal:"Fuente modificada correctamente",
                    selected: [],
                    selectAll: false,
                });
                this.getSource();
            })
            .catch( err => {
                this.setState({ error:err, openUpdate:null });
        });
    }


    /**
        Metodo a cargo de crear el rol con sus permisos asociados
    **/
    handleSubmitAdd = ( event ) => {
        event.persist();

        const sourceData = {
            name: this.state.name,
            nomenclature: this.state.nomenclature,
            web_page: this.state.web_page,
        };

        instance.post('source/', sourceData)
            .then( res => {
                this.setState({
                    errorModal:"Fuente creada correctamente",
                    openAdd:null,
                    name:null,
                    nomenclature:null,
                    web_page:null,
                    selected: [],
                    selectAll: false,
                });
                this.getSource();
            })
            .catch( err => {
                this.setState({ errorModal:err });
        });
    }


    /**
        Metodo a cargo de cerrar el modal
    **/
    handleClose = () => {
        this.setState({
            errorModal:null,
            openUpdate: false,
            openAdd: false,
            name:null,
            nomenclature:null,
            web_page:null,
        });
    }

    /**
        Metodo encargado de activar el modal de Agregar
     **/
    onAddItem = () => {
        this.setState({ errorModal: null, openAdd: true });
    }

    /**
        Metodo encargado de eliminar un elemento o muchos en el backend
     **/
    onDelete = ( idArray ) => {
        for (var i = 0; i < idArray.length; i++) {
            instance.delete('source/'+ idArray[i])
                .then( res => {
                    this.setState({ selected: [], selectAll: false, errorModal:"Fuente/s eliminada/s correctamente" });
                    this.getSource();
                })
                .catch( err => {
                    process.env.NODE_ENV !== 'production' && console.log( err );
                });
        }
    }

    /**
        Metodo encargado de eliminar un elemento o muchos, en el frontend
     **/
    deleteData = ( sources ) => {
        const newData = [...this.state.data];
        for (var i = 0; i < sources.length; i++) {
            for (var j = 0; j < newData.length; j++) {
                if (newData[j].first===sources[i]) {
                    newData.splice(j, 1);
                }
            }
        }
        this.setState({ data: newData });
    }

    handleAllCheckBox = event => {
        let result = handleAllCheckBoxGeneric( event, this.state.data );
        this.setState({ selectAll:result.selectAll, selected:result.selected })
    };

    handleCheckBox = ( event, id) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selected );
        this.setState({ selectAll: false, selected: result.selected });
    };

    handleChangePage = (event, page) => {
        handleChangePageGeneric(event, page, this.getSource )
    };

    isSelected = id => this.state.selected.indexOf( id ) !== -1;


    render () {
        const title = "Fuentes";
        const { error,
                data,
                name,
                nomenclature,
                web_page,
                isLoaded,
                openAdd,
                errorModal,
                openUpdate,
                pagination,
                cleanSelection,
             } = this.state;
        const roles = this.props.roles;
        const rows = [{ id: 'name', numeric: false, disablePadding: true, label: 'Nombre' },
                      { id: 'nomenclature', numeric: false, disablePadding: false, label: 'Nomenclatura' },
                      { id: 'web_page', numeric: false, disablePadding: false, label: 'Link' },
                      { id: '', numeric: false, disablePadding: false, label: '' },
                      { id: '', numeric: false, disablePadding: false, label: '' }];

        if (!isLoaded) {
            return <div> <Spinner /></div>
        } else if(error){
            return <div><h1>{error.message}</h1></div>
        } else {
            return <div>
                <Table
                    errorModal={errorModal}
                    title={title}
                    rows={rows}
                    data={data}
                    viewTable= {true}
                    cleanSelection= {cleanSelection}
                    pagination={pagination}
                    onChangePage={this.getSource}
                    onDelete= { this.onDelete }
                    onAddItem= { this.onAddItem }
                    onUpdate={ this.onUpdate }
                    canCreate={roles.includes('source.create')}
                    canDelete={roles.includes('source.delete')}
                    handleAllCheckBox={this.handleAllCheckBox}
                    handleCheckBox={this.handleCheckBox}
                    handleChangePage={this.handleChangePage}
                    isSelected={this.isSelected}
                    selected={this.state.selected}
                    selectAll={this.state.selectAll}
                />
                {openAdd &&
                    <AddModal
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        nomenclature={nomenclature}
                        name={name}
                        web_page={web_page}
                        handleSubmitAdd={this.handleSubmitAdd}
                        errorModal={errorModal}
                    />
                }
                {openUpdate &&
                    <UpdateModal
                        errorModal={errorModal}
                        handleClose={this.handleClose}
                        handleChange={this.handleChange}
                        nomenclature={nomenclature}
                        name={name}
                        web_page={web_page}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                    />
                }


            </div>
        }
    }
}

export default Source;
