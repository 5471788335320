import React from 'react';
import { Divider, Dialog,DialogContent,DialogTitle }
    from '../materialUi.js';

import { withFormik } from 'formik';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';
import Switch from '@material-ui/core/Switch';
import TextField from "../FormikTextField";
import {Field} from 'formik';
import * as Yup from "yup";
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import useStyles from "../styleGrid"

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        email: Yup.string().email().required('Email requerido'),
        name: Yup.string().required('Nombre requerido'),
    }),

    mapPropsToValues: props => ({
        handleSubmitUpdate: props.handleSubmitUpdate,
        id: props.userData.id,
        sources: props.userData.sources.map( source => ({ id: source.id, value: source.name, label: source.name }) ),
        roles: props.userData.roles.map( role => role.id ),
        email: props.userData.email,
        name: props.userData.name,
        password: null
    }),

    handleSubmit: (values, { setSubmitting }) => {
        const payload = {
            ...values,
            sources: values.sources.map( s => s.id )
        };
        setTimeout(() => {
            values.handleSubmitUpdate( payload );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});

const MyForm = props => {
    const classes = useStyles();

    const {
        values,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        handleClose,
        errorModal,
        rolesOptions,
    } = props;
    const sourcesOptions = props.sourcesOptions.map( source => ({ id: source.id, value: source.name, label: source.name }));
    const rolCargador = rolesOptions.find( role => role.name === 'CARGAR' );
    return (
        <Dialog
            maxWidth="md"
            open={true}
            onClose={handleClose}
            fullScreen={true}
        >
            {errorModal && errorModal.message}
            <form onSubmit={handleSubmit}>
                <DialogTitle style={{marginLeft:"7px"}} id="form-dialog-title">Modificar Usuario</DialogTitle>
                <Divider/>
                <DialogContent>
                    <div className={classes.root}>
                        <Grid container spacing={12}>
                            <Grid item xs={4}>
                                <Field
                                    className = {classes.field}
                                    type="email"
                                    label="Email"
                                    name="email"
                                    margin="normal"
                                    onChange={setFieldValue}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    className = {classes.field}
                                    type="name"
                                    label="Nombre"
                                    name="name"
                                    margin="normal"
                                    onChange={setFieldValue}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Field
                                    className = {classes.field}
                                    type="password"
                                    label="Contraseña"
                                    name="password"
                                    margin="normal"
                                    onChange={setFieldValue}
                                    component={TextField}
                                />
                            </Grid>
                        </Grid>
                        <div className="col">
                            <h3 >Asignar roles</h3>
                            <Switches
                                roles={rolesOptions}
                                value={values.roles}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.roles}
                                cargadorId={rolCargador ? rolCargador.id : -1}
                            />
                        </div>
                        { rolCargador && values.roles.includes( rolCargador.id ) &&
                            <MySelect
                                sources={sourcesOptions}
                                value={values.sources}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.sources}
                            />
                        }
                        <div className="row" style={{marginLeft:"-5px"}} >
                            <Button variant="contained" type="submit" style={{margin:"5px"}}>
                                Actualizar
                            </Button>
                            <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                                Cancelar
                            </Button>
                        </div>
                        <div style={{marginBottom:"200px"}}></div>
                    </div>
                </DialogContent>
            </form>
        </Dialog>);
}

class MySelect extends React.Component {
    handleChange = value => {
        this.props.onChange('sources', value);
    };
    handleBlur = () => {
        this.props.onBlur('sources', true);
    };
    render() {
        return (
            <div>
                <label htmlFor="color">Fuentes</label>
                <Select
                    id="color"
                    options={this.props.sources}
                    isMulti={true}
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder="Puede ingresar multiples fuentes"
                />
                {this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
            </div>);
    }
}

class Switches extends React.Component {
    handleChange = rolId => {
        let markedRoles = [...this.props.value];
        let index = markedRoles.indexOf( rolId );
        if ( index > -1 ) {
            markedRoles.splice( index, 1 );
            if ( rolId === this.props.cargadorId ) this.props.onChange( 'sources', [] );
        } else {
          markedRoles.push( rolId );
        }
        this.props.onChange('roles', markedRoles);
    };
    handleBlur = () => {
        this.props.onBlur('roles', true);
    };
    render() {
        return (
            <div style={{ margin: '1rem 0' }}>
                { this.props.roles.map( rol => (
                    <FormControlLabel
                        key={rol.id}
                        control = {
                            <Switch
                              checked={this.props.value.includes(rol.id)}
                              onChange={() => this.handleChange(rol.id)}
                              onBlur={this.handleBlur}
                            />}
                        label={rol.name}
                    />))
                }
                {this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
        </div>
        );
    }
}


const UpdateModal = formikEnhancer(MyForm);
export default UpdateModal;
