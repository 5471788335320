import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import LoginClean from "./containers/LoginClean/LoginClean";
import FooterClean from "./components/FooterClean/FooterClean";
import * as actions from './store/actions/index';
import SearchAppBar from "./containers/SearchAppBar/SearchAppBar";



class App extends Component {

    componentDidMount () {
        this.props.onTryAutoSignup();
    }

    render() {
        const roles = this.props.roles;
        const userId = this.props.userId;
        return (
            <div>
                { this.props.isAuthenticated
                    ? <div>
                        <style>{'body { background-color: #CFCFC; overflow: hidden }'}</style>
                        <SearchAppBar
                            userName = {this.props.user_name}
                            actor={roles.includes('actor.read')}
                            notice={roles.includes('notice.read') || roles.includes('notice.read.all')}
                            grossNotice={roles.includes('notice.create')}
                            role={roles.includes('userAdministration')}
                            source={roles.includes('source.read')}
                            user={roles.includes('userAdministration')}
                            tag={roles.includes('tag.read')}
                            roles={roles}
                            userId={userId}
                        />
                      </div>
                    : <div>
                        <LoginClean error={this.props.error} />
                        <FooterClean />
                     </div>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        loading: state.auth.loading,
        error: state.auth.error,
        roles: state.auth.roles,
        user_name: state.auth.user_name,
        userId: state.auth.user_id,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onTryAutoSignup: () => dispatch( actions.authCheckState() )
    };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );
