import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { Dialog, Grid, Button }
    from '../materialUi.js';
import ChevronRight from '@material-ui/icons/ChevronRight'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import { tagsToTableConvert }  from './functionsTags';
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

class treeTag extends Component {

    state = {
        selectedTags: [],
        tags:[],
        error: null,
        isLoaded: false,
    }

    componentDidMount(){
        this.getTagsForNotices();
    }

    //este tratamiento es por que la libreria material-table devuelve valores repetidos
    handleCheckBox = ( rows ) => {
        let newTags = [];
        for ( let i = 0; i < rows.length; i++ ) {
            let index = newTags.findIndex( tag => rows[i].id === tag.id );
            if ( index === -1 )
                newTags.push({ id: rows[i].id, value: rows[i].name, label: rows[i].name });
        }
        this.setState({ selectedTags: newTags });
    }

    getTagsForNotices = ( ) => {
        instance.get('tag')
        .then( res => {
            const data = tagsToTableConvert( res.data );
            this.setState({ tags:data, isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }

    render () {
        if (!this.state.isLoaded) {
            return <div> <Spinner /></div>
        } else if(this.state.error){
            return <div><h1>{this.state.error.message}</h1></div>
        } else {
            return <div>
                <Dialog
                    open={true}
                >
                    <MaterialTable
                        data={this.state.tags}
                        columns={[ { title: 'Nombre', field: 'name' } ]}
                        parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                        options={{
                            toolbar:true,
                            search:true,
                            showTitle:false,
                            selection: true,
                            paging:false,
                            showSelectAllCheckbox:true,
                            minBodyHeight:400,
                            maxBodyHeight:400,
                            showTextRowsSelected:false,
                            searchFieldAlignment:'left',
                            padding:'dense',
                        }}
                        onSelectionChange={e => this.handleCheckBox(e)}
                        icons={{
                          DetailPanel: ChevronRight,
                          SortArrow: ArrowDownward,
                          Search: Search,
                          ResetSearch: Clear,
                        }}
                    />
                    <Grid container xs={12} direction={'row'}>
                        <Grid item xs={12}>
                            <Button variant="contained" style={{ margin:"10px"}} onClick={this.props.handleCloseTag}>
                                Cerrar
                            </Button>
                            <Button variant="contained" onClick={() => this.props.updateTags( this.state.selectedTags )}>
                                Agregar
                            </Button>
                        </Grid>
                    </Grid>
                </Dialog>
            </div>
        }
    }
}

export default treeTag;
