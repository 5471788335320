import * as React from 'react';
import MaterialTable from 'material-table';
import TableToolbarGeneric from '../../components/Table/TableToolbarGeneric';
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import ChevronRight from '@material-ui/icons/ChevronRight'
import ArrowDownward from '@material-ui/icons/ArrowDownward'
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';
const treeTag = props => {
    return (
        <div>
            {props.errorModal && <SimpleSnackbar message={props.errorModal}/>}
            <TableToolbarGeneric
                canCreate={props.canCreate}
                canDelete={props.canDelete}
                canTag={props.canTag}
                canReadAll={props.canReadAll}
                title={"Tags"}
                onUpdate={props.onUpdate}
                onAddItem={props.onAddItem}
                onDelete={props.onDelete}
                checked={props.checked}
            />
            <MaterialTable
                data={props.data}
                columns={[ { title: 'Nombre ', field: 'name' }]}
                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                options={{
                    toolbar:true,
                    search:true,
                    showTitle:false,
                    selection: true,
                    paging:false,
                    minBodyHeight:400,
                    maxBodyHeight:400,
                    showTextRowsSelected:false,
                    searchFieldAlignment:'left',
                    padding:'dense',
                }}
                onSelectionChange={e => props.handleCheckBox(e)}
                icons={{
                    DetailPanel: ChevronRight,
                    SortArrow: ArrowDownward,
                    Search: Search,
                    ResetSearch: Clear,
                }}
            />
        </div>
    )
}

export default treeTag;
