import React from 'react';
import PropTypes from 'prop-types';
import TableToolbarGeneric from './TableToolbarGeneric';
import TableHeadGeneric from './TableHeadGeneric';
import { withStyles, Table, TableBody, TableCell, TablePagination, TableRow, Paper, Checkbox } from '../../containers/materialUi.js';
import { SimpleSnackbar } from '../../containers/SimpleSnackbar.js';
import VisibilityIcon from '@material-ui/icons/Visibility';
import IconButton from '@material-ui/core/IconButton';
import AddTagToNotices from '../../containers/Notice/AddTagToNotices';
import UpdateModal from '../../containers/Notice/UpdateModal';
import Spinner from '../Spinner/Spinner'
import useStyles from "../../containers/styleGrid"
import Grid from '@material-ui/core/Grid';

function MainTable( props ) {
    const classes = useStyles();
    const { searchValue, selected, selectAll, rows, data, title, pagination, canCreate, canDelete, canExport, canTag, canReadAll, viewTable, errorModal, messageFilter } = props;
    const { total, rowsPerPage } = pagination;
    const currentPage = pagination.currentPage-1;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, total - currentPage * rowsPerPage);

    return (
        <div className={classes.root}>
            { title==="Registros" ?
            <Grid container xs={12}>
                <Grid item xs={4}>
                    <Paper>
                        {errorModal && <SimpleSnackbar message={errorModal}/>}
                        <TableToolbarGeneric
                            canCreate={canCreate}
                            canDelete={canDelete}
                            canExport={canExport}
                            canTag={canTag}
                            canReadAll={canReadAll}
                            title={title}
                            messageFilter={messageFilter}
                            onTagMany={props.onTagMany}
                            onUpdate={props.onUpdate}
                            onAddItem={props.onAddItem}
                            onDelete={props.onDelete}
                            onFilter={props.onFilter}
                            onExportTxt={props.onExportTxt}
                            onFixActor={props.onFixActor}
                            numSelected={selectAll ? total : selected.length}
                            checked={selected}
                            selectAll={selectAll}
                        />
                        { viewTable &&
                            <Grid>
                                <Table size='dense' style={{ tableLayout: 'auto' }} className={classes.table} aria-labelledby="tableTitle">
                                    <TableHeadGeneric
                                        numSelected={selectAll ? data.length : selected.length}
                                        onSelectAllClick={props.handleAllCheckBox}
                                        rowCount={data.length}
                                        rows={rows}
                                    />
                                    <TableBody>
                                    {  (!props.isLoaded ) ? <Spinner/> :
                                            data.map(n => {
                                                const isSelected = props.isSelected(n.first);
                                                let arr = [n.first];
                                                return (
                                                    <TableRow
                                                        style={{backgroundColor: '#fcfcfc', padding:"0px"}}
                                                        hover={true}
                                                        role="checkbox"
                                                        aria-checked={isSelected}
                                                        tabIndex={-1}
                                                        key={n.first}
                                                        selected={isSelected}
                                                    >
                                                        <TableCell padding="checkbox" style={{padding:"0px"}}>
                                                            <Checkbox onClick={event => props.handleCheckBox(event, n.first) } checked={isSelected} />
                                                        </TableCell>

                                                        <TableCell align="left" style={{padding:"0px"}}>
                                                            <IconButton onClick={() => props.onUpdate(arr)} aria-label="edit">
                                                                <VisibilityIcon fontSize="small" />
                                                            </IconButton>
                                                        </TableCell>
                                                        <TableCell style={{paddingRight:"20px", paddingLeft:"0px"}} align="left" component="th">{n.second}</TableCell>
                                                        <TableCell style={{paddingRight:"0px"}} align="left" component="th" scope="row" padding="none">{n.third}</TableCell>
                                                    </TableRow>
                                                );
                                            })
                                        }
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 49 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                                <TablePagination
                                    style={{ backgroundColor: '#cfcfc'}}
                                    rowsPerPageOptions={[9]}
                                    component="div"
                                    count={total}
                                    rowsPerPage={rowsPerPage}
                                    page={currentPage}
                                    backIconButtonProps={{
                                    'aria-label': 'Previous Page',
                                    }}
                                    nextIconButtonProps={{
                                    'aria-label': 'Next Page',
                                    }}
                                    onChangePage={props.handleChangePage}
                                />
                            </Grid>
                        }
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    { props.openTagMany &&
                        <Paper>
                            <AddTagToNotices
                                handleTag={props.handleTag}
                                tags={props.tags}
                                handleCloseTag={props.handleCloseTag}
                            />
                        </Paper>
                    }

                    { props.openUpdate && <Paper> <UpdateModal
                        noticeData={props.noticeData}
                        handleChange={props.handleChange}
                        handleTag={props.handleTag}
                        handleClose={props.handleClose}
                        handleSubmitUpdate={props.handleSubmitUpdate}
                        orbits={props.orbits}
                        sectors={props.sectors}
                        types={props.types}
                        ambits={props.ambits}
                        sources={props.sources}
                        actors={props.actors}
                        getActors={props.getActors}
                        errorModal={props.errorModal}
                        readOnly={props.readOnly}
                        canExport={props.canExport}
                        canCreate={props.canCreate}
                        canTag={props.canTag}
                        handleCloseTag={props.handleCloseTag}
                    /></Paper>}
                </Grid>
            </Grid>
            :
            <Paper>
                {errorModal && <SimpleSnackbar message={errorModal}/>}

                <TableToolbarGeneric
                    canCreate={canCreate}
                    canDelete={canDelete}
                    canExport={canExport}
                    canReadAll={canReadAll}
                    title={title}
                    onUpdate={props.onUpdate}
                    onAddItem={props.onAddItem}
                    onSearch={props.onSearch}
                    onDelete={props.onDelete}
                    onFilter={props.onFilter}
                    onExportTxt={props.onExportTxt}
                    onFixActor={props.onFixActor}
                    numSelected={selectAll ? total : selected.length}
                    checked={selected}
                    selectAll={selectAll}
                    searchValue={searchValue}

                />
                { viewTable &&
                    <div>
                        <div className={classes.tableWrapper}>
                            <Table className={classes.table} aria-labelledby="tableTitle">
                                <TableHeadGeneric
                                    numSelected={selectAll ? data.length : selected.length}
                                    onSelectAllClick={ props.handleAllCheckBox }
                                    rowCount={data.length}
                                    rows={rows}
                                />
                                <TableBody>

                                    {data.map(n => {
                                        const isSelected = props.isSelected(n.first);
                                        return (
                                            <TableRow
                                                style={{ backgroundColor: '#fcfcfc'}}
                                                hover
                                                onClick={event => props.handleCheckBox(event, n.first)}
                                                role="checkbox"
                                                aria-checked={isSelected}
                                                tabIndex={-1}
                                                key={n.first}
                                                selected={isSelected}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox checked={isSelected} />
                                                </TableCell>
                                                <TableCell align="left">{n.second}</TableCell>
                                                <TableCell align="left" component="th" scope="row" padding="none">{n.third}</TableCell>
                                                <TableCell align="left">{n.fourth}</TableCell>
                                                <TableCell align="left">{n.fiveth}</TableCell>
                                                <TableCell align="left">{n.sixth}</TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 49 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </div>
                        <TablePagination
                            style={{ backgroundColor: '#cfcfc'}}
                            rowsPerPageOptions={[9]}
                            component="div"
                            count={total}
                            rowsPerPage={rowsPerPage}
                            page={currentPage}
                            backIconButtonProps={{
                            'aria-label': 'Previous Page',
                            }}
                            nextIconButtonProps={{
                            'aria-label': 'Next Page',
                            }}
                            onChangePage={props.handleChangePage}
                        />
                    </div>
                }
            </Paper>
            }
        </div>
    );
}

MainTable.propTypes = {
    classes: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    rows: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    onChangePage: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onAddItem:PropTypes.func.isRequired,
    onSearch: PropTypes.func,
    onExportTxt: PropTypes.func,
    title: PropTypes.string.isRequired,
    onFilter:PropTypes.func,
    onFixActor: PropTypes.func,
    errorModal: PropTypes.string,
};

export default withStyles()(MainTable);
