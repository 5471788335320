import React from 'react';
import { Button, Divider, Dialog,DialogContent,DialogTitle }
    from '../materialUi.js';
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import Grid from '@material-ui/core/Grid';
import useStyles from "../styleGrid"


export default ( props ) => {
    const classes = useStyles();

    const { errorModal,
        handleClose,
        handleChange,
        handleSubmitUpdate,
        name,
        description,
        } = props;
    return <div>
            <Dialog
                fullScreen={true}
                open={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                {errorModal && <SimpleSnackbar message={errorModal}/>}
                <ValidatorForm onSubmit={handleSubmitUpdate}>
                    <DialogTitle style={{marginLeft:"35px"}} id="form-dialog-title">Actualizar actor</DialogTitle>
                    <Divider/>
                    <DialogContent>
                        <div className={classes.root}>
                            <Grid container spacing={12}>
                                <Grid item xs={4}>
                                    <TextValidator
                                        className={classes.field}
                                        id="name"
                                        name="name"
                                        value={name}
                                        label="Nombre"
                                        onChange={handleChange}
                                        margin="normal"
                                        validators={["required"]}
                                        errorMessages={["El campo es obligatorio"]}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <TextValidator
                                        className={classes.field}
                                        id="description"
                                        name="description"
                                        value={description}
                                        label="Descripcion"
                                        rowsMax={20}
                                        rows={1}
                                        multiline
                                        onChange={handleChange}
                                        margin="normal"
                                        variant="filled"
                                        style={{
                                            width: '100%',
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </div>

                        <div className="row" style={{marginLeft:"30px"}} >
                            <Button variant="contained" type="submit" style={{margin:"5px"}}>
                                Actualizar
                            </Button>
                            <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                                Cancelar
                            </Button>
                        </div>
                    </DialogContent>
                </ValidatorForm>
            </Dialog>
        </div>;
}
