import './formik-demo.css';
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Field } from 'formik';
import { Grid, ExpandMoreIcon,  Fab, AddIcon, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails,
    Typography, Button, FormControl, DialogContent, MenuItem, MuiThemeProvider } from '../materialUi.js';
import TextField from "../FormikTextField";
import ChipInput from 'material-ui-chip-input';
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import AddTagToNotice from './AddTagToNotice.js';
import useStyles from "../../containers/styleGrid"

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        topics: Yup.array()
            .min(1, 'Elige almenos 1 actor')
            .of(
                Yup.object().shape({
                    label: Yup.string().required(),
                    value: Yup.string().required(),
                })
            ),
        tags: Yup.array()
        .of(
          Yup.object().shape({
            label: Yup.string(""),
            value: Yup.string(""),
          })
        ),
        links: Yup.array()
            .min(1, 'Elige almenos 1 link')
            .of( Yup.string() ),
        source: Yup.string().required('Selección requerida'),
        date: Yup.date().required('Fecha requerida'),
        title: Yup.string().required('Titulo requerido'),
        content: Yup.string().required('Contenido requerido'),
        sector: Yup.string().required('Selección requerida'),
        type: Yup.string().required('Tipo requerido'),
        orbit: Yup.string().required('Selección requerida'),
        ambit: Yup.string().required('Selección requerida'),
    }),
    enableReinitialize: true,
    mapPropsToValues: props => ({
        handleSubmitUpdate: props.handleSubmitUpdate,
        handleTag: props.handleTag,
        source: props.noticeData.source_id,
        topics: props.noticeData.actors,
        tags: props.noticeData.tags,
        links: props.noticeData.links.split(","),
        date: props.noticeData.date,
        title: props.noticeData.title,
        content: props.noticeData.content,
        sector: props.noticeData.sector,
        orbit: props.noticeData.orbit,
        ambit: props.noticeData.ambit,
        type: props.noticeData.type,
        idNotice:props.noticeData.idNotice,
    }),

    handleSubmit: (values, { setSubmitting }) => {

        const payload = {
            ...values,
            topics: values.topics.map(t => t.id),
            links: values.links.join(),
        };

        setTimeout(() => {
            values.handleSubmitUpdate( payload );
            setSubmitting(false);
            }, 1000);
    },
    displayName: 'MyForm',
});

const getIds = ( tagIdParams ) => {
    const newTagIdParams = [];
    for (var i = 0; i < tagIdParams.length; i++) {
        newTagIdParams.push(tagIdParams[i].id)
    }
    return newTagIdParams;
}

const MyForm = props => {

    const classes = useStyles();
    const {
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        sources,
        ambits,
        types,
        sectors,
        actors,
        getActors,
        orbits,
        handleClose,
        errorModal,
        readOnly,
        canCreate,
        canExport,
        canTag,
        onTagUnique,
    } = props;
    let newSources = sources.map( source => ({ id: source.id ,value: source.name, label: source.name }) );
    let newActors = actors.map( actor => ({ id: actor.id ,value: actor.name, label: actor.name }) );

    return (
        <div className={classes.root}>
            {errorModal && <SimpleSnackbar message={errorModal}/>}
            <form onSubmit={handleSubmit}>
                <DialogContent style={{ backgroundColor: '#fcfcfc'}} >
                    { canCreate ?
                        <Grid container xs={12} direction={'row'}>
                            <Grid item xs={2}>
                                <Field
                                    type="date"
                                    name="date"
                                    component={TextField}
                                    label="Fecha"
                                />
                            </Grid>
                            <Grid item xs={8}>
                                <Field
                                    type="title"
                                    name="title"
                                    component={TextField}
                                    label="Titulo"
                                    style={{ width:"80%" }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <Field
                                    name="source"
                                    select
                                    component={TextField}
                                    label="Fuente"
                                    >
                                    {newSources.map(s => (
                                    <MenuItem value={s.id} key={s.id}>
                                        {s.value}
                                    </MenuItem>
                                  ))}
                                </Field>
                            </Grid>
                            <Grid item xs={12}>
                                <MySelectLink
                                    values={values.links}
                                    onChange={setFieldValue}
                                    error={errors.links}
                                    touched={touched.links}
                                    readOnly={false}
                                    msgPlaceholder="Puede ingresar multiples links"
                                />
                            </Grid>

                            <Grid item container xs={12}>
                                <Grid item xs={12}>
                                    <Field
                                        type="content"
                                        name="content"
                                        label="Contenido"
                                        component={TextField}
                                        margin="normal"
                                        variant="filled"
                                        rowsMax={20}
                                        rows={20}
                                        style={{
                                            width: '100%',
                                        }}
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <ExpansionPanel>
                                    <ExpansionPanelSummary expandIcon={ <ExpandMoreIcon /> }>
                                        <Typography>Desplegar</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                    <Grid item xs={12}>
                                        <MySelectActors
                                            newActors={newActors}
                                            value={values.topics}
                                            onChange={setFieldValue}
                                            getActors={getActors}
                                            onBlur={setFieldTouched}
                                            error={errors.topics}
                                            touched={touched.topics}
                                        />
                                    </Grid>
                                    </ExpansionPanelDetails>
                                    <ExpansionPanelDetails>
                                    <Grid container xs={12} direction={'row'}>
                                        <Grid item xs={3}>
                                            <FormControl >
                                                <Field
                                                    style={{ width:"140px"}}
                                                    name="type"
                                                    label="Tipo"
                                                    select
                                                    component={TextField}
                                                >
                                                    {types.map( types => (
                                                    <MenuItem value={types} key={types}>
                                                        {types}
                                                    </MenuItem>
                                                    ))}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl >
                                                <Field
                                                    style={{ width:"140px"}}
                                                    name="ambit"
                                                    label="Ambito"
                                                    select
                                                    component={TextField}
                                                    >
                                                    {ambits.map( ambit => (
                                                    <MenuItem value={ambit} key={ambit}>
                                                        {ambit}
                                                    </MenuItem>
                                                  ))}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl >
                                                <Field
                                                    style={{ width:"140px"}}
                                                    name="orbit"
                                                    label="Orbita"
                                                    select
                                                    component={TextField}
                                                    >
                                                    {orbits.map( orbit => (
                                                    <MenuItem value={orbit} key={orbit}>
                                                        {orbit}
                                                    </MenuItem>
                                                  ))}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <FormControl >
                                                <Field
                                                    style={{ width:"140px"}}
                                                    name="sector"
                                                    select
                                                    component={TextField}
                                                    label="Sector"
                                                >
                                                    {sectors.map( sector => (
                                                        <MenuItem value={sector} key={sector}>
                                                            {sector}
                                                        </MenuItem>
                                                      ))}
                                                </Field>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </Grid>
                        <Grid item xs={12} style={{ marginTop:"5px"}} >
                            <Button variant="contained" type="submit">
                                Actualizar
                            </Button>
                            <Button variant="contained" style={{ marginLeft:"10px"}} onClick={handleClose}>
                                Cancelar
                            </Button>
                        </Grid>
                    </Grid>
                    : ( readOnly || canExport ) ?
                    <Grid container xs={12} direction={'row'}>
                        <Grid item xs={2}>
                            <Field
                                type="date"
                                name="date"
                                component={TextField}
                                label="Fecha"
                                disabled
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <Field
                                disabled
                                type="title"
                                name="title"
                                component={TextField}
                                label="Titulo"
                                style={{ width:"80%" }}
                            />
                        </Grid>
                        <Grid item xs={2}>
                            <Field
                                disabled
                                style={{ width:"130px"}}
                                name="source"
                                label="Fuente"
                                select
                                component={TextField}
                                >
                                {newSources.map(s => (
                                <MenuItem value={s.id} key={s.id}>
                                    {s.value}
                                </MenuItem>
                              ))}
                            </Field>
                        </Grid>
                        <Grid item xs={12}>
                            <MySelectLink
                                values={values.links}
                                onChange={setFieldValue}
                                error={errors.links}
                                touched={touched.links}
                                readOnly={true}
                                msgPlaceholder=""
                                label="Links"
                            />
                        </Grid>
                        <Grid item container xs={12}>
                            <Grid item xs={12}>
                                <Field
                                    disabled
                                    type="content"
                                    name="content"
                                    label="Contenido"
                                    component={TextField}
                                    margin="normal"
                                    variant="filled"
                                    rowsMax={20}
                                    rows={20}
                                    style={{
                                        width: '100%',
                                    }}
                                    multiline
                                />
                            </Grid>
                        </Grid>
                        { canTag &&
                        <Grid item xs={12}>
                            <SelectTags
                                value={values.tags}
                                onChange={setFieldValue}
                                onBlur={setFieldTouched}
                                error={errors.tags}
                                touched={touched.tags}
                                onTagUnique={onTagUnique}
                            />
                        </Grid>
                        }
                        <Grid item container xs={12} style={{marginTop:"20px"}}>
                            <Button variant="contained" size="small" onClick={handleClose}>
                                Cerrar
                            </Button>
                            { canTag &&
                            <Button style={{ marginLeft:"15px"}} variant="contained"
                                onClick={() => values.tags ? values.handleTag( getIds(values.tags)) : values.handleTag( [] )}>
                                Actualizar
                            </Button>
                            }
                        </Grid>


                    </Grid>
                    : <div></div>}
                </DialogContent>
            </form>
        </div>
    );
};

const customStyles = {
  control: (base, state) => ({
    ...base,
    background: "#fcfcfc",
    // match with the menu
    borderRadius: state.isFocused ? "1px 1px 0 0" : 3,
    // Overwrittes the different states of border
    borderColor: state.isFocused ? "grey" : "grey",
    // Removes weird border around container
    boxShadow: state.isFocused ? null : null,
    "&:hover": {
      // Overwrittes the different states of border
      borderColor: state.isFocused ? "#605A73" : "#605A73"
    }
  }),
  menu: base => ({
    ...base,
    // override border radius to match the box
    borderRadius: 0,
    // kill the gap
    marginTop: 0
  }),
  menuList: base => ({
    ...base,
    // kill the white space on first and last option
    padding: 0
  })
};

class SelectTags extends React.Component {

    state={
        openTreeTag:false,
    }

    handleChange = value => {
        this.props.onChange( 'tags', value );
    }

    handleAddTag = values => {
        let newTags = [];
        for ( let i = 0; i < values.length; i++ ) {
            let index = this.props.value.findIndex( e => e.id === values[i].id );
            if ( index === -1 )
                newTags.push( values[i] );
        }
        let tags = newTags.concat( [...this.props.value] );
        this.props.onChange( 'tags', tags );
        this.setState({ openTreeTag:false });
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur('tags', true);
    };

    handleCloseTag = () => {
        this.setState({
            openTreeTag:false,
        });
    }

    render() {
        return (
            <Grid container style={{ width:"100%" }} xs={12}>
                <Grid item xs={1}>
                    <Fab style={{ marginTop:"25px", marginLeft:"15px", backgroundColor: '#605a73' }}
                        size="small"
                        color='secondary'
                        title="Agregar tags"
                        onClick={() => this.setState({ openTreeTag:true })}>
                        <AddIcon />
                    </Fab>
                </Grid>
                <Grid item xs={11}>
                    <label htmlFor="color">TAGS</label>
                    <Select
                        id="color"
                        isMulti={true}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        value={this.props.value}
                        placeholder="Solo puede eliminar tags"
                        isClearable={true}
                        isOptionDisabled={false}
                        styles={customStyles}
                        skipDisabled={true}
                        closeOnSelect={false}
                        removeSelected={false}
                    />
                </Grid>
                {this.state.openTreeTag &&
                    <AddTagToNotice
                        handleCloseTag={this.handleCloseTag}
                        updateTags={this.handleAddTag}
                    />
                }

            </Grid>
        );
    }
}

class MySelectActors extends React.Component {

    onInputChange = input => {
        this.props.getActors( input );
    }

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange('topics', value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur('topics', true);
    };

    render() {
        return (
            <div style={{ width:"100%" }}>
                <label htmlFor="color">ACTORES</label>
                <Select
                    id="color"
                    options={this.props.newActors}
                    isMulti={true}
                    onChange={this.handleChange}
                    onInputChange={this.onInputChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder="Puede ingresar multiples actores"
                    styles={customStyles}
                />
                {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
        </div>
        );
    }
}


class MySelectLink extends React.Component {

    links=this.props.values;

    handleChange = () => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange( 'links', this.links );
    };

    handleAddChip = chip => {
      this.links.push( chip );
      this.handleChange();
    };

    handleDeleteChip = ( chip, index ) => {
      this.links.splice( index, 1 );
      this.handleChange();
    };

    showLinks = link => {
      if ( link.length > 40 )
        return <a href={link} rel="noopener noreferrer" target="_blank">{link.substring( 0, 40 )+' ...'}</a>;
      return <a href={link} rel="noopener noreferrer" target="_blank">{link}</a>;
    }

    render() {
        const valuesShort = this.props.values.map( value => this.showLinks( value ) );
        return (
            <Grid>
              <MuiThemeProvider style={{borderColor:"grey.500"}}>
                <ChipInput
                    readOnly={this.props.readOnly}
                    value={valuesShort}
                    onAdd={(chip) => this.handleAddChip(chip)}
                    onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                    fullWidth={true}
                    placeholder={this.props.msgPlaceholder}
                    label="Links"
                />
              </MuiThemeProvider>
              {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
              )}
        </Grid>
        );
    }
}

const AddModal = formikEnhancer(MyForm);
export default AddModal;
