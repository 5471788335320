import React from 'react';

const FooterClean = ( props ) => {
  return (
    <div className="footer-clean">
        <footer>
            <div className="container">
                <div className="row justify-content-left">
                    <div>
                    </div>
                </div>
            </div>
        </footer>
    </div>
  )
}

export default FooterClean;
