export const handleAllCheckBoxGeneric = ( event, data ) => {
    let selected = [];
    if ( event.target.checked ) {
        selected = data.map( item => item.first );
        return { selectAll:true, selected:selected }
    }
    return { selectAll:false, selected:[] }
};

export const handleCheckBoxGeneric = ( event, id, selected ) => {
    const selectedIndex = selected.indexOf( id );
    let newSelected = [];

    if ( selectedIndex === -1 ) {
        newSelected = newSelected.concat( selected, id );
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat( selected.slice( 1 ) );
    } else if ( selectedIndex === selected.length - 1 ) {
        newSelected = newSelected.concat( selected.slice( 0, -1 ) );
    } else if ( selectedIndex > 0 ) {
        newSelected = newSelected.concat(
        selected.slice( 0, selectedIndex ),
        selected.slice( selectedIndex + 1 ),
      );
    }
    return { selectAll:false, selected:newSelected }
};

export const handleChangePageGeneric = ( event, page, fun ) => {
    fun( page+1 );
};
