import React, { Component } from 'react';
import Table from "../../components/Table/Table";
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import AddModal  from './AddModal';
import UpdateModal  from './UpdateModal';
import FixModal  from './FixModal';
import { handleAllCheckBoxGeneric, handleCheckBoxGeneric, handleChangePageGeneric } from '../TableFunctions.js';

class Actor extends Component {

    state = {
        data: [],
        actors: [],
        error: null,
        isLoaded: false,
        name: null,
        description: null,
        idActor: null,
        errorModal: null,
        openAdd:false,
        openUpdate:null,
        pagination: {},
        openFix:false,
        checkedFix:[],
        namesFix:[],
        cleanSelection: false,
        selected: [],
        selectAll: false,
        searchValue: "",
    }

    componentDidMount() {
        this.getActors();
    }

    getActorsOptimized = ( filter ) => {
        if ( filter && filter.length >= 2 ) {
            instance.get( 'actor?filter='+filter )
            .then( res => {
                this.setState({ actors:res.data , isLoaded:true });
            })
            .catch( err => {
                this.setState({ error:err, isLoaded:true });
            });
        } else {
            this.setState({ actors:[] , isLoaded:true });
        }
    }

    handleSearch = ( searchValue ) => {
        this.setState({ searchValue : searchValue }, () => this.getActors())
    }

    getActors = ( page=1 ) => {
        let url = 'actor?page='+ page ;
        if ( this.state.searchValue.length > 0 ) {
            url = url+"&search="+ this.state.searchValue;
        }

        instance.get( url )
        .then( res => {
            const { total, current_page, per_page, data } = res.data;
            var genericData = data.map( item => {
                return { first: item.id, second: item.name, third:item.description };
            });
            this.setState({ data:genericData, pagination: { total, currentPage: current_page, rowsPerPage: per_page }, isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    handleChange = event => {
        this.setState({
            [ event.target.id ]: event.target.value
        });
    }

    /**
        Metodo a cargo de cerrar el modal
    **/
    handleClose = () => {
        this.setState({
            markedRoles:[],
            markedPermission:[],
            errorModal:null,
            openUpdate: false,
            openAdd: false,
            openFix: false,
            name:null
        });
    }

    /**
        Metodo encargado de eliminar un elemento o muchos en el backend
     **/
    onDelete = ( idArray ) => {
        for (var i = 0; i < idArray.length; i++) {
            instance.delete('actor/'+ idArray[i])
                .then( res => {
                    this.setState({ selected: [], selectAll: false, errorModal:"Actor/es eliminado/s correctamente" });
                    this.getActors();
                })
                .catch( err => {
                    process.env.NODE_ENV !== 'production' && console.log( err );
                });
        }
    }

    /**
        Metodo encargado de activar el modal de arreglar
     **/
    onFixActor = ( arr=[] ) => {
        if ( arr.length === 0 ) {
            instance.get( 'actor?selectall='+ this.state.searchValue )
            .then( res => {
                this.setState({ cleanSelection: false, namesFix: res.data, openFix: true, checkedFix:res.data.map( actor => actor.id) });
            });
        }else{
            instance.get( 'actor?list='+arr.join() )
            .then( res => {
                this.setState({ cleanSelection: false, namesFix: res.data, openFix: true, checkedFix:arr });
            });
        }
    }

    /**
        Metodo encargado de activar el modal de Arreglar actores
     **/
    onAddItem = () => {
        this.setState({ openAdd: true, errorModal:null });
    }

    /**
        Metodo encargado de activar el modal de actualizar
     **/
    onUpdate = ( idArray ) => {
        const promises = [];
        promises.push( this.getActorId( idArray[0] ) );
        Promise.all( promises ).then( res => {
            for (let i = 0; i < this.state.data.length; i++) {
                if ((this.state.data[i].first)===idArray[0]) {
                    this.setState({ name: this.state.data[i].second,
                        description:this.state.data[i].third,
                    },  () => { this.setState({cleanSelection: false, openUpdate: true, errorModal: null })});
                    return;
                }
            }
        });
    }

    /**
        Metodo a cargo de traer los permisos asociados a el rol
    **/
    getActorId = (id) => {
        instance.get('/actor/'+ id)
        .then( res => {
            this.setState({ name:res.data.name, description:res.data.description, idActor:res.data.id});
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }


    /**
        Metodo a cargo de crear el actor
    **/
    handleSubmitAdd = ( event ) => {
        event.preventDefault();

        const actorData = {
            name: this.state.name,
            description: this.state.description,
        };

        instance.post('actor/', actorData)
            .then( res => {
                this.setState({
                    errorModal:"Actor creado correctamente",
                    openAdd:null,
                    name:null,
                    description:null,
                });
                this.getActors();
            })
            .catch( err => {
                process.env.NODE_ENV !== 'production' && console.log( err );
                this.setState({ errorModal:err });
        });
    }


    /**
        Metodo a cargo de crear el actor
    **/
    handleSubmitFix = ( event ) => {
        this.setState({ isLoaded:false });
        const dataFix = {
            actorsOld: this.state.checkedFix,
            actorsNew: event.topics,
        };
        instance.post('actor/override', dataFix)
            .then( res => {
                this.setState({
                    openFix:false,
                    checkedFix:[],
                    namesFix: [],
                    selected:[],
                    cleanSelection: true,
                    errorModal:"Actor/es arreglado/s correctamente",
                    searchValue:"",
                });
                this.getActors();
            })
            .catch( err => {
                if ( err.response.status === 400 ) {
                    err = "ERROR, No se puede reemplazar actores por el mismo"
                }
                this.setState({ errorModal:err,
                     openFix:false,
                     checkedFix:[],
                     namesFix: [],
                     cleanSelection: true,
                });
        });
    }

    /**
        Metodo a cargo de actualizar el rol con sus permisos asociados
    **/
    handleSubmitUpdate = ( event ) => {
        event.preventDefault();

        const actorData = {
            name: this.state.name,
            description: this.state.description
        };

        const path = 'actor/'+ this.state.idActor;
        instance.put(path, actorData)
            .then( res => {
                this.setState({
                    markedPermission:[],
                    idUser: null,
                    openUpdate:null,
                    name:null,
                    description:null,
                    errorModal:"Actor modificado correctamente",
                    cleanSelection: true,
                });
                this.getActors();
            })
            .catch( err => {
                this.setState({ error:err, openUpdate:null });
        });
    }

    handleAllCheckBox = event => {
        let result = handleAllCheckBoxGeneric( event, this.state.data );
        this.setState({ selectAll:result.selectAll, selected:result.selected })
    };

    handleCheckBox = ( event, id) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selected );
        this.setState({ selectAll: false, selected: result.selected });
    };

    handleChangePage = (event, page) => {
        handleChangePageGeneric(event, page, this.getActors )
    };

    isSelected = id => this.state.selected.indexOf( id ) !== -1;

    render () {
        const title = "Actores";
        const { error,
                data,
                actors,
                name,
                description,
                isLoaded,
                openAdd,
                openUpdate,
                openFix,
                pagination,
                errorModal,
                namesFix,
                cleanSelection,
                searchValue,
             } = this.state;
        const roles = this.props.roles;
        const rows = [
            { id: 'name', numeric: false, disablePadding: true, label: 'Nombre' },
            { id: 'description', numeric: false, disablePadding: false, label: 'Descripción' },
            { id: '', numeric: false, disablePadding: true, label: '' },
            { id: '', numeric: false, disablePadding: false, label: '' },
            { id: '', numeric: false, disablePadding: false, label: '' }
                    ];
        if (!isLoaded) {
            return <div> <Spinner /></div>
        } else if(error){
            return <div><h1>{error.message}</h1></div>
        } else {
            return <div>
                <Table
                    errorModal={errorModal}
                    viewTable= {true}
                    title={ title }
                    rows={ rows }
                    data={ data }
                    pagination={ pagination }
                    cleanSelection={ cleanSelection }
                    onChangePage={ this.getActors }
                    onDelete= { this.onDelete }
                    onAddItem= { this.onAddItem }
                    onSearch= { this.handleSearch }
                    onFixActor= { this.onFixActor }
                    onUpdate={ this.onUpdate }
                    canCreate={roles.includes('actor.create')}
                    canDelete={roles.includes('actor.delete')}
                    handleAllCheckBox={this.handleAllCheckBox}
                    handleCheckBox={this.handleCheckBox}
                    handleChangePage={this.handleChangePage}
                    isSelected={this.isSelected}
                    selected={this.state.selected}
                    selectAll={this.state.selectAll}
                    searchValue={searchValue}
                />
                {openAdd &&
                    <AddModal
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        description={description}
                        name={name}
                        handleSubmitAdd={this.handleSubmitAdd}
                        errorModal={errorModal}
                    />
                }
                {openUpdate &&
                    <UpdateModal
                        errorModal={errorModal}
                        handleClose={this.handleClose}
                        handleChange={this.handleChange}
                        name={name}
                        description={description}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                    />
                }
                {openFix &&
                    <FixModal
                        errorModal={errorModal}
                        handleClose={this.handleClose}
                        handleChange={this.handleChange}
                        actors={actors}
                        getActors={this.getActorsOptimized}
                        description={description}
                        handleSubmitFix={this.handleSubmitFix}
                        namesFix={namesFix}
                    />
                }

            </div>
        }
    }
}

export default Actor;
