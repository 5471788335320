import axios from 'axios';

let instance;

const refresh = () => {
    let token = 'Bearer '+localStorage.getItem( 'access_token' );
    instance = axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'Authorization': token
        }
    });
};

refresh();

export { refresh, instance, axios };
