import { searchItem, addItem, showTitle, filterItem, editItem, numItemSelected, deleteItem, tagItem, fixItem, exportItem } from './funcionalities';
import React from 'react';
import { withStyles, Toolbar } from '../../containers/materialUi.js';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    input: {
        marginLeft: "20px",
        flex: 1,
    },
    iconButton: {
        padding: 10,
    },
    divider: {
        height: 28,
        margin: 4,
    },
}));
//Header of the table, is all funcionalities from user
const TableToolbarGeneric = props => {
    const classes = useStyles();
    const { numSelected, onDelete, onAddItem, checked, onSearch, onUpdate, onFixActor, onTagMany, searchValue,
        onExportTxt, title, onFilter, selectAll, canCreate, canDelete, canExport, canTag, canReadAll, messageFilter } = props;
    const classStyle = classNames(classes.root, { [classes.highlight]: numSelected > 0 });
    if ( title==="Registros" ) {
        return (
            <Toolbar
                style={{ minHeight: '80px', backgroundColor: '#fcfcfc'}}>
                { checked.length === 0 && canCreate && addItem( classes, onAddItem ) }
                { checked.length === 0 && showTitle( classes, title) }
                { checked.length === 0 && filterItem( onFilter, messageFilter ) }
                { checked.length === 1 && numItemSelected( classes, numSelected ) }
                { checked.length === 1 && ( canCreate || canReadAll ) }
                { checked.length === 1 && canExport && exportItem( onExportTxt, numSelected, checked, selectAll ) }
                { checked.length === 1 && canDelete && deleteItem( onDelete, checked ) }
                { checked.length >= 2 && !selectAll && numItemSelected( classes, numSelected ) }
                { checked.length >= 2 && !selectAll && canTag && tagItem( onTagMany, numSelected, checked, selectAll ) }
                { checked.length >= 2 && !selectAll && canExport && exportItem( onExportTxt, numSelected, checked, selectAll ) }
                { checked.length >= 2 && !selectAll && canDelete && deleteItem( onDelete, checked ) }
                { selectAll && numItemSelected( classes, numSelected ) }
                { selectAll && canExport && exportItem( onExportTxt, numSelected, checked, selectAll ) }
                { selectAll && canTag && tagItem( onTagMany, numSelected, checked, selectAll ) }
            </Toolbar> );
    } else if ( title==="Actores" ) {
        return (
            <Toolbar
                style={{ minHeight: '80px', backgroundColor: '#fcfcfc'}}
                className={classStyle}>
                { checked.length === 0 && canCreate && addItem( classes, onAddItem ) }
                { checked.length === 0 && showTitle( classes, title) }
                { checked.length === 0 && searchItem( classes, onSearch, searchValue ) }
                { !selectAll && checked.length === 1 && numItemSelected( classes, numSelected ) }
                { !selectAll && checked.length === 1 && canCreate && editItem( onUpdate, checked ) }
                { !selectAll && checked.length === 1 && canCreate && fixItem( onFixActor, checked ) }
                { !selectAll && checked.length === 1 && canDelete && deleteItem( onDelete, checked ) }
                { selectAll && checked.length >= 1 && numItemSelected( classes, numSelected ) }
                { selectAll && checked.length >= 1 && canCreate && fixItem( onFixActor, checked, selectAll ) }
                { !selectAll && checked.length >= 2 && numItemSelected( classes, numSelected ) }
                { !selectAll && checked.length >= 2 && canCreate && fixItem( onFixActor, checked ) }
                { !selectAll && checked.length >= 2 && canDelete && deleteItem( onDelete, checked ) }
            </Toolbar> );
    } else if ( title==="Tags" ) {
        return (
            <Toolbar
                style={{ minHeight: '80px', backgroundColor: '#fcfcfc'}}
                className={classStyle}>
                { checked.length === 0 && canCreate && addItem( classes, onAddItem ) }
                { checked.length === 0 && showTitle( classes, title) }
                { checked.length === 1 && numItemSelected( classes, checked.length ) }
                { checked.length === 1 && canCreate && editItem( onUpdate, checked ) }
                { checked.length === 1 && canDelete && deleteItem( onDelete, checked ) }
                { checked.length >= 2 && numItemSelected( classes, checked.length ) }
                { checked.length >= 2 && canDelete && deleteItem( onDelete, checked ) }

            </Toolbar> );
    } else {
        return (
            <Toolbar
                style={{ minHeight: '80px', backgroundColor: '#fcfcfc'}}
                className={classStyle}>
                { checked.length === 0 && canCreate && addItem( classes, onAddItem ) }
                { checked.length === 0 && showTitle( classes, title) }
                { checked.length === 1 && numItemSelected( classes, numSelected ) }
                { checked.length === 1 && canCreate && editItem( onUpdate, checked ) }
                { checked.length === 1 && canDelete && deleteItem( onDelete, checked ) }
                { checked.length >= 2 && !selectAll && numItemSelected( classes, numSelected ) }
                { checked.length >= 2 && !selectAll && canDelete && deleteItem( onDelete, checked ) }
                { selectAll && numItemSelected( classes, numSelected ) }
            </Toolbar> );
    }
};

export default withStyles()(TableToolbarGeneric);
