import React from 'react';
import { DialogTitle, Divider, Button, Dialog, DialogContent }
    from '../materialUi.js';
import {ValidatorForm, TextValidator} from "react-material-ui-form-validator";
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import Grid from '@material-ui/core/Grid';
import useStyles from "../styleGrid"


export default ( props ) => {
    const classes = useStyles();

    const { errorModal,
        handleClose,
        handleChange,
        handleSubmitUpdate,
        name,
        web_page,
        nomenclature,
        } = props;
    return <div>
            <Dialog
                fullScreen={true}
                open={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >

            {errorModal && <SimpleSnackbar message={errorModal}/>}
                <ValidatorForm onSubmit={handleSubmitUpdate}>
                    <DialogTitle style={{marginLeft:"35px"}} id="form-dialog-title">Actualizar fuente</DialogTitle>
                    <Divider />
                    <DialogContent>
                        <div className={classes.root}>
                            <Grid container spacing={12}>
                                <Grid item xs={4}>
                                    <TextValidator
                                        className={classes.field}
                                        id="name"
                                        name="name"
                                        value={name || ''}
                                        label="Nombre"
                                        onChange={handleChange}
                                        margin="normal"
                                        validators={["required"]}
                                        errorMessages={["El campo es obligatorio"]}
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextValidator
                                        className={classes.field}
                                        id="nomenclature"
                                        name="nomenclature"
                                        value={nomenclature || ''}
                                        label="Nomenclatura"
                                        onChange={handleChange}
                                        margin="normal"
                                        validators={["required"]}
                                        errorMessages={["El campo es obligatorio"]}
                                    />
                                </Grid>
                                <Grid item xs={5}>
                                    <TextValidator
                                        className={classes.field}
                                        id="web_page"
                                        name="web_page"
                                        value={web_page || ''}
                                        label="Link"
                                        onChange={handleChange}
                                        margin="normal"
                                        validators={["required"]}
                                        errorMessages={["El campo es obligatorio"]}
                                    />
                                </Grid>
                            </Grid>
                            <div className="row" style={{marginLeft:"29px", marginTop:"15px"}} >
                                <Button variant="contained" type="submit" style={{margin:"5px"}}>
                                    Actualizar
                                </Button>
                                <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                                    Cancelar
                                </Button>
                            </div>
                        </div>
                    </DialogContent>
                </ValidatorForm>
            </Dialog>
        </div>;
}
