import React from 'react';
import { Button,
    FormControlLabel,
    Switch,
    TextField,
    Dialog,
    Divider,
    DialogContent,
    DialogTitle }
    from '../materialUi.js';
import { SimpleSnackbar } from '../SimpleSnackbar.js';

export default ( props ) => {
    const {
    	markedPermission,
        handleClose,
        handleChange,
        handleSubmitUpdate,
        name,
        handleChangeSelectPermission,
        permission,
        customIndexOf,
        errorModal } = props;
    return <div>
            <Dialog
                fullScreen={true}
                open={true}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
            {errorModal && <SimpleSnackbar message={errorModal}/>}
                <DialogTitle style={{marginLeft:"18px"}}  id="form-dialog-title">Editar rol</DialogTitle>
                <Divider />
                <DialogContent>
                    <form onSubmit={handleSubmitUpdate}>
                    <div className="col">
                        <div className="form-group">
                            <TextField
                                style={{width:"100%"}}
                                className="form-control"
                                id="name"
                                name="name"
                                value={name}
                                label="Nombre del rol"
                                onChange={handleChange}
                                margin="normal"
                            />
                        </div>
                    </div>
                        <div style={{marinTop:"20px"}} className="col">
                            <h3 >Modificar permisos</h3>
                            {permission.map(p => {
                                return (
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                onChange={() => handleChangeSelectPermission(p)}
                                                value={p.name}
                                                key={p.id}
                                                checked={customIndexOf( p, markedPermission ) !== -1}
                                          />
                                        }
                                        label={p.name}
                                    />
                                );
                            })}
                        </div>
                        <div className="row" style={{marginLeft:"15px"}} >
                            <Button variant="contained" type="submit" style={{margin:"5px"}}>
                                Actualizar
                            </Button>
                            <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                                Cancelar
                            </Button>
                        </div>
                    </form>
                </DialogContent>

            </Dialog>
        </div>;
}
