import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Link } from "react-router-dom";
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {  withStyles, Drawer, CssBaseline, AppBar, Toolbar, List,
    Typography, Divider, IconButton, MenuIcon, ChevronLeftIcon,
    ListItem, ListItemText
 } from '../materialUi.js';
 import User from "../../containers/User/User";
 import Role from "../../containers/Role/Role";
 import Actor from "../../containers/Actor/Actor";
 import Source from "../../containers/Source/Source";
 import Notice from "../../containers/Notice/Notice";
 import GrossNotice from "../GrossNotice/GrossNotice";
 import Tag from "../../containers/Tag/Tag";
 import { Route, Redirect, Switch } from 'react-router-dom';
 import NotFound from "../../components/NotFound/NotFound";
import SubtitlesIcon from '@material-ui/icons/Subtitles';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import LanguageIcon from '@material-ui/icons/Language';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuBookIcon from '@material-ui/icons/MenuBook';

const drawerWidth = 170;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(7),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    marginTop:65,
    flexGrow: 1,
    height: '90vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function MenuSideBar(props) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const { onLogout, userName, user, role, notice, grossNotice, actor, source, tag, roles, userId } = props;
  return (
    <div className={classes.root}>
        {console.log("render SearchAppBar")}
        <CssBaseline />
            <AppBar style={{ backgroundColor: '#CFCFCF'}} position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        style={{ color:'#ffff', backgroundColor: '#605a73'}}
                        className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>

                    </Typography>
                    <IconButton style={{ color:'#ffff', backgroundColor: '#605a73'}} title={userName} color="inherit">
                        <AccountCircleIcon/>
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{ paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose) }}
                open={open}
            >
                <div className={classes.toolbarIcon}>
                    <IconButton onClick={handleDrawerClose}>
                        <ChevronLeftIcon />
                    </IconButton>
                </div>
                <Divider />
                <List component="nav" onClick={handleDrawerClose}>

                    { notice && <Link to="/notice" style={{ textDecoration: 'none', color:"black" }}>
                        <ListItem button>
                            <SubtitlesIcon color="action"/>
                            <ListItemText primary="Registros" style={{marginLeft:"20px"}}/>
                        </ListItem>
                    </Link> }

                    { actor && <Link to='/actor' style={{ textDecoration: 'none', color:"black" }}>
                        <ListItem button>
                            <RecentActorsIcon color="action"/>
                            <ListItemText primary="Actores" style={{marginLeft:"20px"}}/>
                        </ListItem>
                    </Link> }

                    { source && <Link to="/source" style={{ textDecoration: 'none', color:"black" }}>
                        <ListItem button>
                            <LanguageIcon color="action"/>
                            <ListItemText primary="Fuentes" style={{marginLeft:"20px"}}/>
                        </ListItem>
                    </Link> }

                    { tag && <Link to="/tag" style={{ textDecoration: 'none', color:"black" }}>
                        <ListItem button>
                            <LocalOfferIcon color="action"/>
                            <ListItemText primary="Tags" style={{marginLeft:"20px"}}/>
                        </ListItem>
                    </Link> }

                    { role && <Link to="/role" style={{ textDecoration: 'none', color:"black" }}>
                        <ListItem button>
                            <SupervisorAccountIcon color="action"/>
                            <ListItemText primary="Roles" style={{ marginLeft:"20px" }}/>
                        </ListItem>
                    </Link> }

                    { user && <Link to="/user" style={{ textDecoration: 'none', color:"black" }}>
                        <ListItem button>
                            <PersonIcon color="action"/>
                            <ListItemText primary="Usuarios" style={{ marginLeft:"20px" }}/>
                        </ListItem>
                    </Link> }

                    { grossNotice && <Link to="/grossnotice" style={{ textDecoration: 'none', color:"black" }}>
                        <ListItem button>
                            <MenuBookIcon color="action"/>
                            <ListItemText primary="Noticias" style={{ marginLeft:"20px" }}/>
                        </ListItem>
                    </Link> }

                    <ListItem button onClick={onLogout} style={{ color:"black" }}>
                        <ExitToAppIcon color="action"/>
                        <ListItemText primary="Salir" style={{ marginLeft:"20px" }}/>
                    </ListItem>
                </List>

            </Drawer>
            <main className={classes.content}>
                <div>
                <style>{'body { background-color: #CFCFC; }'}</style>
                    <Switch>
                        <Route exact path="/" render={() => user ? <Redirect to="/user" /> : <Redirect to="/notice" /> } />
                        <Route path="/user" exact component={() => user ? <User roles={roles} /> : <Redirect to="/" /> } />
                        <Route path="/role" exact component={() => role ? <Role roles={roles} /> : <Redirect to="/" /> } />
                        <Route path="/tag" exact component={() => tag ? <Tag roles={roles} /> : <Redirect to="/" /> } />
                        <Route path="/notice" exact component={() => notice ? <Notice roles={roles} userId={userId} /> : <Redirect to="/" /> } />
                        <Route path="/grossnotice" exact component={() => grossNotice ? <GrossNotice userId={userId} /> : <Redirect to="/" /> } />
                        <Route path="/actor" exact component={() => actor ? <Actor roles={roles} /> : <Redirect to="/" /> } />
                        <Route path="/source" exact component={() => source ? <Source roles={roles} /> : <Redirect to="/" /> } />
                        <Route component={NotFound} />
                    </Switch>
                </div>
            </main>
        </div>
  );
}
MenuSideBar.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

const mapDispatchToProps = dispatch => {
    return {
        onLogout: () => dispatch(actions.logout())
    };
};
export default connect(null,mapDispatchToProps)(withStyles(useStyles, { withTheme: true })(MenuSideBar));
