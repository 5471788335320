import React from 'react';
import * as actions from '../../store/actions/index';

import { connect } from 'react-redux';
import * as Yup from "yup";
import {Formik, Field, Form} from 'formik';
import { Button } from '../materialUi.js';
import TextField from "../FormikTextField";
import { makeStyles } from '@material-ui/styles';
import Container from '@material-ui/core/Container';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: "64px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: "8px",
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: "8px",
  },
  submit: {
    marginTop: "10px",
    width:"100%",
  },
}));
//Header of the table, is all funcionalities from user
const LoginClean = props => {
    const classes = useStyles();

    const handleSubmit = event => {
        props.onAuth( event.name, event.password );
    }

    const validationForm = Yup.object().shape({
        name: Yup.string().required('Nombre requerido'),
        password: Yup.string().required('Password requerido'),
    });
    const error = props.error;
    return (
        <Container component="main" maxWidth="xs">
        <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                  Sign in
                </Typography>
                <Formik
                    initialValues={{name: '', password: ''}}
                    validationSchema={validationForm}
                    onSubmit={handleSubmit}
                    className={classes.form} noValidate
                    render={() => (
                    <Form>
                        <Field
                            variant="outlined"
                            component={TextField}
                            name="name"
                            label="Nombre"
                            required
                            fullWidth
                            placeholder="name"
                        />
                        <Field
                            variant="outlined"
                            component={TextField}
                            type="password"
                            name="password"
                            required
                            fullWidth
                            placeholder="password"
                            label="Password"
                        />
                        {error && <div style={{ color: 'red', marginTop: '.5rem' }}>{error.message}</div>}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            >
                            Entrar
                        </Button>
                        <p>dtbn © 2020</p>
                </Form>
                )}
            />
            </div>
        </Container>
    );
}

const mapDispatchToProps = dispatch => {
    return {
        onAuth: ( name, password ) => dispatch( actions
            .auth( name, password ) )
    };
};


export default connect( null, mapDispatchToProps )( LoginClean );



// import React, { Component } from 'react';
// import * as actions from '../../store/actions/index';
//
// import { connect } from 'react-redux';
// import * as Yup from "yup";
// import {Formik, Field, Form} from 'formik';
// import { Button } from '../materialUi.js';
// import TextField from "../FormikTextField";
// import { makeStyles } from '@material-ui/styles';
//
// const useStyles = makeStyles(theme => ({
//     root: {
//         display: 'flex',
//         alignItems: 'center',
//     },
//     input: {
//         marginLeft: "20px",
//         flex: 1,
//     },
//     iconButton: {
//         padding: 10,
//     },
//     divider: {
//         height: 28,
//         margin: 4,
//     },
// }));
// //Header of the table, is all funcionalities from user
// const LoginClean = props => {
//     const classes = useStyles();
//
//     const handleSubmit = event => {
//         props.onAuth( event.name, event.password );
//     }
//
//     const validationForm = Yup.object().shape({
//         name: Yup.string().required('Nombre requerido'),
//         password: Yup.string().required('Password requerido'),
//     });
//     const error = props.error;
//     return (
//         <div style={{ display: 'flex', alignItems: 'center',}}>
//             <Formik
//                 initialValues={{name: '', password: ''}}
//                 validationSchema={validationForm}
//                 onSubmit={handleSubmit}
//                 render={() => (
//                 <Form>
//                     <div className="illustration"><i className="far fa-newspaper"></i></div>
//                         <div width="33%" className="form-group">
//                             <Field
//                                 variant="outlined"
//                                 component={TextField}
//                                 name="name"
//                                 label="Nombre"
//                                 placeholder="name"
//                             />
//                             <Field
//                                 variant="outlined"
//                                 component={TextField}
//                                 type="password"
//                                 name="password"
//                                 placeholder="password"
//                                 label="Password"
//                             />
//                             {error && <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error.message}</div>}
//                         </div>
//                         <Button variant="contained" type="submit" color="primary">
//                             Login
//                         </Button>
//                         <div style={{textAlign: 'center'}}></div>
//                         <p>dtbn © 2020</p>
//
//                 </Form>
//                 )}
//             />
//         </div>
//     );
// }
//
// const mapDispatchToProps = dispatch => {
//     return {
//         onAuth: ( name, password ) => dispatch( actions
//             .auth( name, password ) )
//     };
// };
//
//
// export default connect( null, mapDispatchToProps )( LoginClean );
