import React, { Component } from 'react';
import { Button, DialogContent , Grid} from '../materialUi.js';
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import * as Yup from 'yup';
import { withFormik } from 'formik';
import MaterialTable from 'material-table';
import ChevronRight from '@material-ui/icons/ChevronRight';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Search from '@material-ui/icons/Search';
import Clear from '@material-ui/icons/Clear';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        tags: Yup.array()
          .min(1, 'Elige almenos 1 tags')
          .of(
            Yup.object().shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
          ),
    }),
});

class treeTags extends Component {

    state = {
        selectedTags: [],
        tags:[],
        error: null,
        isLoaded: false,
    }

    //este tratamiento exrta es por que la libreria material-table devuelve valores repetidos
    handleCheckBox = ( rows ) => {
        let newTags = [];
        for ( let i = 0; i < rows.length; i++ ) {
            let index = newTags.findIndex( tag => rows[i].id === tag.id );
            if ( index === -1 )
                newTags.push( rows[i].id );
        }
        this.setState({ selectedTags: newTags });
    }

    render(){
        return (
            <div>
                <DialogContent
                    open={true}
                    onClose={this.props.handleCloseTag}
                    style={{ height:600, maxWidth: "none" }}
                >
                    <Grid container xs={12} direction={'row'}>
                        {this.props.errorModal && <SimpleSnackbar message={this.props.errorModal}/>}
                        <Grid container xs={12} direction={'row'}>
                            <Grid item xs={12}>
                                <MaterialTable
                                    data={this.props.tags}
                                    columns={[ { title: 'Nombre', field: 'name' } ]}
                                    parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                    options={{
                                        toolbar:true,
                                        search:true,
                                        showTitle:false,
                                        selection: true,
                                        paging:false,
                                        showSelectAllCheckbox:true,
                                        minBodyHeight:500,
                                        maxBodyHeight:500,
                                        showTextRowsSelected:false,
                                        searchFieldAlignment:'left',
                                        padding:'dense',
                                    }}
                                        onSelectionChange={e => this.handleCheckBox(e)}
                                    icons={{
                                        DetailPanel: ChevronRight,
                                        SortArrow: ArrowDownward,
                                        Search: Search,
                                        ResetSearch: Clear,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogContent>
                <Grid container xs={12} direction={'row'}>
                    <Grid item xs={5} style={{marginLeft:"30px", margin:"10px"}} >
                        <Button
                            variant="contained"
                            onClick={( ) => this.props.handleTag( this.state.selectedTags )}
                            type="submit"
                            style={{ margin:"10px"}}
                        >
                            Asignar
                        </Button>
                        <Button style={{margin:"10px"}} variant="contained" onClick={this.props.handleCloseTag} >
                            Cancelar
                        </Button>
                    </Grid>
                </Grid>

            </div>
        )
    }
}

const TagModal = formikEnhancer(treeTags);
export default TagModal;
