import React from 'react';
import { TableCell, TableHead, TableRow, Checkbox } from '../../containers/materialUi.js';

function TableHeadGeneric( props ) {

    const { onSelectAllClick, numSelected, rowCount, rows } = props;

    return (
            <TableHead>
                <TableRow style={{ backgroundColor: '#fcfcfc'}}>
                    <TableCell padding="checkbox">
                        <Checkbox
                            indeterminate={numSelected > 0 && numSelected < rowCount}
                            checked={numSelected === rowCount}
                            onChange={onSelectAllClick}
                        />
                    </TableCell>
                        {rows.map(row => (
                                <TableCell
                                    key={row.id}
                                    align={row.numeric ? 'left' : 'left'}
                                    padding={row.disablePadding ? 'none' : 'default'}
                                >
                                    {row.label}
                            </TableCell>
                        ),this,
                        )}
                </TableRow>
            </TableHead>
    );

}

export default TableHeadGeneric;
