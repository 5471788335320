import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Button, Dialog,DialogContent,DialogTitle } from '../materialUi.js';
import { SimpleSnackbar } from '../SimpleSnackbar.js';


const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        topics: Yup.array()
          .min(1, 'Elige almenos 1 actor')
          .of(
            Yup.object().shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
          ),
    }),

    mapPropsToValues: props => ({
        handleSubmitFix: props.handleSubmitFix,
        topics: [],
    }),

    handleSubmit: (values, { setSubmitting }) => {
        const payload = {
            ...values,
            topics: values.topics.map(t => t.id),
        };
        setTimeout(() => {
            values.handleSubmitFix( payload );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});


const MyForm = props => {
    const {
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        actors,
        getActors,
        handleClose,
        errorModal,
        namesFix,
    } = props;

    const newActors = actors.map( actor => ({ id: actor.id, value: actor.name, label: actor.name }));
    return (
    <div>
        <Dialog
            open={true}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen={true}
        >
        {errorModal && <SimpleSnackbar message={errorModal}/>}
        <form onSubmit={handleSubmit}>
            <DialogTitle style={{ marginLeft: "8px", backgroundColor: '#fcfcfc'}} id="form-dialog-title"> </DialogTitle>
            <div className="row" style={{ marginLeft: "35px"}}>
                <h5>Los siguientes actores: {" "}</h5>
                {namesFix.map(n => (
                    <h5>{n.name} {" "}{"-"}{" "}</h5>
                ))} <h5>seran reemplazados por los indicados en el siguiente campo: </h5>
            </div>
            <DialogContent style={{ backgroundColor: '#fcfcfc'}} >
                <div className="row">
                    <div className="col">
                        <div className="col">
                            <MySelect
                                newActors={newActors}
                                value={values.topics}
                                onChange={setFieldValue}
                                getActors={getActors}
                                onBlur={setFieldTouched}
                                error={errors.topics}
                                touched={touched.topics}
                            />
                        </div>
                        <div className="row" style={{ marginLeft:"10px", marginTop:"20px"}}>
                            <Button variant="contained" type="submit">
                                Reemplazar
                            </Button>
                            <Button variant="contained" style={{ marginLeft:"10px"}} onClick={handleClose}>
                                Cancelar
                            </Button>
                        </div>
                        <div style={{marginBottom:"200px"}}></div>

                    </div>
                </div>
            </DialogContent>
        </form>
        </Dialog>
    </div>
  );
};


class MySelect extends React.Component {

    onInputChange = input => {
        this.props.getActors( input );
    }

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange('topics', value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur('topics', true);
    };

    render() {
        return (
            <div style={{ width:"97%", marginTop: "0px", marginLeft:"-5px" }}>
                <Select
                    options={this.props.newActors}
                    isMulti={true}
                    onChange={this.handleChange}
                    onInputChange={this.onInputChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder="Puede ingresar multiples actores"
                />
                {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
        </div>
        );
    }
}

const AddModal = formikEnhancer(MyForm);
export default AddModal;
