import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { instance } from '../getInstanceAxios.js';
import Icon from '@material-ui/core/Icon';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Divider from '@material-ui/core/Divider';
import moment from 'moment';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    overflow: 'hidden'
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: '20px'
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    maxWidth: 150,
  },
}));

export default function FormDialog( props ) {
  const classes = useStyles();
  const [sources, setSources] = React.useState([]);
  const [source, setSource] = React.useState("");
  const [loading, setLoading] = React.useState(true);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [fromDate, setFromDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [fromTime, setFromTime] = React.useState('00:00');
  const [toDate, setToDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [toTime, setToTime] = React.useState(moment().format('HH:mm'));

  const handleSubmitUpdate = () => {
    setLoading( true );
    instance.get('grossnotice/update?source_id='+source.id).then( res => {
      setOpenSuccess( true );
      setLoading( false );
    }).catch( err => {
      setOpenError(true);
      setLoading( false );
    });
  };

  const handleSubmitDownload = () => {
    const from = moment(fromDate+fromTime, 'YYYY-MM-DDHH:mm').unix();
    const to = moment(toDate+toTime, 'YYYY-MM-DDHH:mm').unix();
    setLoading( true );
    instance.get('grossnotice?source_id='+source.id+'&from='+from+'&to='+to).then( res => {
      let fileDownload = require( 'js-file-download' );
      fileDownload( res.data, 'notas.txt' );
      setLoading( false );
    }).catch( err => {
      setOpenError(true);
      setLoading( false );
    });
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSuccess(false);
    setOpenError(false);
  };

  React.useEffect(() => {
    console.log( "useeffect" );
    instance.get( 'user/'+props.userId+'/source' ).then( res => {
      let sources = res.data.map(s => ({id: s.id, name: s.name}));
      setSources(sources);
      setLoading( false );
    }).catch( err => {
      console.log( "err", err );
    });
  }, [] );

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Paper className={classes.paper} elevation={3} style={{maxWidth: '400px'}}>
            <h3>Descargar las noticias en el periodo seleccionado</h3>
            <TextField
              id="fromDate"
              label="Desde"
              type="date"
              required
              onChange={(e) => setFromDate(e.target.value)}
              value={fromDate}
              className={classes.textField}
              InputLabelProps={{shrink: true}}
            />
            <TextField
              id="fromTime"
              label="Hora"
              type="time"
              required
              onChange={(e) => setFromTime(e.target.value)}
              value={fromTime}
              className={classes.textField}
              InputLabelProps={{shrink: true}}
              inputProps={{step: 300}}
            />
          </Paper>
          <Paper className={classes.paper} elevation={3} style={{maxWidth: '400px'}}>
            <TextField
              id="toDate"
              label="Hasta"
              type="date"
              required
              onChange={(e) => setToDate(e.target.value)}
              value={toDate}
              className={classes.textField}
              InputLabelProps={{shrink: true}}
            />
            <TextField
              id="toTime"
              label="Hora"
              type="time"
              required
              onChange={(e) => setToTime(e.target.value)}
              value={toTime}
              className={classes.textField}
              InputLabelProps={{shrink: true}}
              inputProps={{step: 300}}
            />
          </Paper>
          <Paper className={classes.paper} elevation={3} style={{maxWidth: '400px'}}>
            <Select
              id="sources"
              labelId="Fuente"
              value={source.name}
              onChange={(event) => setSource({id: event.target.value, name: event.target.name})}
              style={{width: '120px'}}
            >
              {sources.map(source => <MenuItem value={source.id}>{source.name}</MenuItem>)}
            </Select>
            <Button onClick={handleSubmitDownload} disabled={loading} color="primary" variant="contained" style={{margin: '20px'}}>
              Descargar
            </Button>
          </Paper>
        </Grid>
          <Divider orientation="vertical" flexItem />
        <Grid item xs={5}>
          <Paper className={classes.paper} elevation={3} style={{maxWidth: '400px'}}>
            <h3>Actualizar las últimas noticias</h3>
            <Select
              id="sources"
              labelId="Fuente"
              value={source.name}
              onChange={(event) => setSource({id: event.target.value, name: event.target.name})}
              style={{width: '120px'}}
            >
              {sources.map(source => <MenuItem value={source.id}>{source.name}</MenuItem>)}
            </Select>
            <Button onClick={handleSubmitUpdate} disabled={loading} color="primary" variant="contained" style={{margin: '20px'}}>
              Actualizar
            </Button>
          </Paper>
        </Grid>

        <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Actualizado!
          </Alert>
        </Snackbar>
        <Snackbar open={openError} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Ocurrió un error!
          </Alert>
        </Snackbar>
      </Grid>
    </div>
  );
}
