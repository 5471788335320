import React from 'react';
import { Divider, Button, Switch, FormControlLabel, Dialog, DialogContent, DialogTitle }
    from '../materialUi.js';
import { withFormik } from 'formik';
import Select from 'react-select';
import TextField from "../FormikTextField";
import {Field} from 'formik';
import * as Yup from "yup";
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import useStyles from "../styleGrid"
import Grid from '@material-ui/core/Grid';

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        name: Yup.string().required('Nombre de tag requerido'),
    }),

    mapPropsToValues: props => ({
        handleSubmitUpdate: props.handleSubmitUpdate,
        name: props.name,
        tags: props.tags,
    }),

    handleSubmit: (values, { setSubmitting }) => {
        const payload = {
            ...values,
            name: values.name,
            tags: values.tags.map(t => t.id),

        };
        setTimeout(() => {
            values.handleSubmitUpdate( payload );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});

const MyForm = props => {
    const classes = useStyles();
    
    const {
        values,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        handleClose,
        errorModal,
        getTagsParent,
        tagsOptions,
        switchFather,
    } = props;
    let tagsOption = tagsOptions.map( to => ({ id: to.id ,value: to.name, label: to.name }) );
    return (
        <Dialog
            maxWidth="md"
            open={true}
            onClose={handleClose}
            fullScreen={true}
        >
            {errorModal && <SimpleSnackbar message={errorModal}/>}
            <form onSubmit={handleSubmit}>
                <DialogTitle style={{marginLeft:"7px"}} id="form-dialog-title">Actualizar Tag</DialogTitle>
                <Divider />
                <DialogContent>
                    <div className={classes.root}>
                        <Grid container spacing={6}>
                            <Grid item xs={3}>
                                <Field
                                    type="name"
                                    label="Nombre"
                                    name="name"
                                    margin="normal"
                                    onChange={setFieldValue}
                                    component={TextField}
                                />
                            </Grid>
                            <Grid item xs={3}>
                                <FormControlLabel
                                    label="Actualizar tags padres"
                                    style={{marginTop:"20px"}}
                                    control={
                                        <Switch
                                            onChange={() => props.handleChangeSwitch()}
                                        />
                                    }
                                />
                            </Grid>
                        </Grid>
                    </div>
                    {   !switchFather &&
                        <MySelect
                            options={tagsOption}
                            value={values.tags}
                            onChange={setFieldValue}
                            getTagsParent={getTagsParent}
                            onBlur={setFieldTouched}
                            error={errors.tags}
                        />
                    }

                    <div className="row" style={{marginLeft:"-5px"}} >
                        <Button variant="contained" type="submit" style={{margin:"5px"}}>
                            Confirmar
                        </Button>
                        <Button variant="contained" onClick={handleClose} style={{margin:"5px"}}>
                            Cancelar
                        </Button>
                    </div>
                    <div style={{marginBottom:"200px"}}></div>

                </DialogContent>
            </form>
        </Dialog>);
}

class MySelect extends React.Component {
    onInputChange = input => {
        this.props.getTagsParent( input );
    }

    handleChange = value => {
        this.props.onChange('tags', value);
    };

    handleBlur = () => {
        this.props.onBlur('tags', true);
    };
    render() {
        return (
            <div>
                <label htmlFor="color">TAGS PADRES</label>
                <Select
                    id="color"
                    options={this.props.options}
                    isMulti={true}
                    onChange={this.handleChange}
                    onInputChange={this.onInputChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder="Puede ingresar multiples tags padres"
                />
                {this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
            </div>);
    }
}

const UpdateModal = formikEnhancer(MyForm);
export default UpdateModal;
