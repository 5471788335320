import './formik-demo.css';
import React from 'react';
import { withFormik } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import { Field } from 'formik';
import { SimpleSnackbar } from '../SimpleSnackbar.js';
import { FormControl, Button, MenuItem, Dialog, Grid, DialogContent, DialogTitle, MuiThemeProvider } from '../materialUi.js';
import TextField from "../FormikTextField";
import ChipInput from 'material-ui-chip-input';
import useStyles from "../styleGrid"

const formikEnhancer = withFormik({
    validationSchema: Yup.object().shape({
        topics: Yup.array()
          .min(1, 'Elige almenos 1 actor')
          .of(
            Yup.object().shape({
              label: Yup.string().required(),
              value: Yup.string().required(),
            })
          ),
        links: Yup.array()
          .min(1, 'Elige almenos 1 link')
          .of(
            Yup.string()),
        source: Yup.string().required('Selección requerida'),
        date: Yup.date().required('Fecha requerida'),
        title: Yup.string().required('Titulo requerido'),
        type: Yup.string().required('Tipo requerido'),
        content: Yup.string().required('Contenido requerido'),
        sector: Yup.string().required('Selección requerida'),
        orbit: Yup.string().required('Selección requerida'),
        ambit: Yup.string().required('Selección requerida'),
    }),

    mapPropsToValues: props => ({
        handleSubmitAdd: props.handleSubmitAdd,
        source: '',
        type:'',
        topics: [],
        links: [],
        date: props.dateNow,
        title: '',
        content: '',
        sector: '',
        orbit: '',
        ambit: '',
    }),

    handleSubmit: (values, { setSubmitting }) => {
        const payload = {
            ...values,
            topics: values.topics.map(t => t.id),
            links: values.links.join(),
        };

        setTimeout(() => {
            values.handleSubmitAdd( payload );
            setSubmitting(false);
        }, 1000);
    },
    displayName: 'MyForm',
});


const MyForm = props => {

    const classes = useStyles();
    const {
        values,
        touched,
        errors,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        ambits,
        sectors,
        actors,
        getActors,
        orbits,
        types,
        handleClose,
        errorModal,
        sources,
    } = props;

    let newSources = sources.map( source => ({ id: source.id ,value: source.name, label: source.name }) );
    let newActors = actors.map( actor => ({ id: actor.id ,value: actor.name, label: actor.name }) );

    return (
    <div>
        <Dialog
            open={true}
            onClose={handleClose}
            fullScreen={true}
        >

        {errorModal && <SimpleSnackbar message={errorModal}/>}
            <form onSubmit={handleSubmit}>
                <DialogTitle>REGISTRO</DialogTitle>
                <DialogContent style={{ backgroundColor: '#fcfcfc'}} >
                    <div className={classes.root}>
                        <Grid item container xs={12} direction={'row'}>
                            <Grid container xs={6} direction={'column'}>
                                <Grid container xs={12} direction={'row'}>
                                    <Grid item xs={4}>
                                        <Field
                                            style={{ width:"140px" }}
                                            type="date"
                                            name="date"
                                            component={TextField}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                            label="Fecha"
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <Field
                                            style={{ width:"180px" }}
                                            name="source"
                                            select
                                            component={TextField}
                                            label="Fuente"
                                        >
                                            {newSources.map(s => (
                                                <MenuItem value={s.id} key={s.id}>
                                                    {s.value}
                                                </MenuItem>
                                             ))}
                                        </Field>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl>
                                            <Field
                                                style={{ width:"180px" }}
                                                name="type"
                                                select
                                                component={TextField}
                                                label="Tipo"
                                            >
                                                {types.map(type => (
                                                <MenuItem value={type} key={type}>
                                                    {type}
                                                </MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <MySelectLink
                                            label="LINKS"
                                            values={values.links}
                                            onChange={setFieldValue}
                                            error={errors.links}
                                            touched={touched.links}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl>
                                            <Field
                                                style={{ width:"180px"}}
                                                name="ambit"
                                                select
                                                label="Ambito"
                                                component={TextField}
                                                >
                                                {ambits.map( ambit => (
                                                    <MenuItem value={ambit} key={ambit}>
                                                        {ambit}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl>
                                            <Field
                                                style={{ width:"180px"}}
                                                name="orbit"
                                                label="Orbita"
                                                select
                                                component={TextField}
                                                >
                                                {orbits.map( orbit => (
                                                <MenuItem value={orbit} key={orbit}>
                                                    {orbit}
                                                </MenuItem>
                                              ))}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl>
                                            <Field
                                                style={{ width:"180px"}}
                                                name="sector"
                                                label="Sector"
                                                select
                                                component={TextField}
                                            >
                                                {sectors.map( sector => (
                                                    <MenuItem value={sector} key={sector}>
                                                        {sector}
                                                    </MenuItem>
                                                  ))}
                                            </Field>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MySelect
                                            newActors={newActors}
                                            value={values.topics}
                                            onChange={setFieldValue}
                                            getActors={getActors}
                                            onBlur={setFieldTouched}
                                            error={errors.topics}
                                            touched={touched.topics}

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Field
                                            type="title"
                                            name="title"
                                            component={TextField}
                                            margin="normal"
                                            label="Titulo"
                                            style={{
                                                width: '90%',
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button variant="contained" type="submit">
                                            Crear
                                        </Button>
                                        <Button variant="contained" style={{ marginLeft:"10px"}} onClick={handleClose}>
                                            Cancelar
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item container xs={6}>
                                <Grid item xs={12}>
                                    <Field
                                        type="content"
                                        name="content"
                                        label="Contenido"
                                        component={TextField}
                                        margin="normal"
                                        variant="filled"
                                        rowsMax={20}
                                        rows={20}
                                        style={{
                                            width: '100%',
                                        }}
                                        multiline
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </div>
                </DialogContent>
            </form>
        </Dialog>
    </div>
  );
};

class MySelect extends React.Component {

    onInputChange = input => {
        this.props.getActors( input );
    }

    handleChange = value => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange('topics', value);
    };

    handleBlur = () => {
        // this is going to call setFieldTouched and manually update touched.topcis
        this.props.onBlur('topics', true);
    };



    render() {
        return (
            <div style={{ width:"90%", marginTop: "20px" }}>
                <label>ACTORES</label>
                <Select
                    options={this.props.newActors}
                    isMulti={true}
                    onChange={this.handleChange}
                    onInputChange={this.onInputChange}
                    onBlur={this.handleBlur}
                    value={this.props.value}
                    placeholder="Puede ingresar multiples actores"

                />
                {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
                )}
        </div>
        );
    }
}

class MySelectLink extends React.Component {

    links=[];

    handleChange = () => {
        // this is going to call setFieldValue and manually update values.topcis
        this.props.onChange( 'links', this.links );
    };

    handleAddChip = chip => {
      this.links.push( chip );
      this.handleChange();
    };

    handleDeleteChip = ( chip, index ) => {
      this.links.splice( index, 1 );
      this.handleChange();
    };

    showLinks = link => {
      if ( link.length > 75 )
        return link.substring( 0, 72 )+' ...';
      return link;
    }

    render() {
        const valuesShort = this.props.values.map( value => this.showLinks( value ) );
        return (
            <div style={{ width:"90%",marginTop:"30px" }}>
              <MuiThemeProvider>
                <ChipInput
                    value={valuesShort}
                    onAdd={(chip) => this.handleAddChip(chip)}
                    onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                    fullWidth={true}
                    placeholder="Puede ingresar multiples links"
                />
              </MuiThemeProvider>
              {!!this.props.error && this.props.touched && (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>{this.props.error}</div>
              )}
        </div>
        );
    }
}

const AddModal = formikEnhancer(MyForm);
export default AddModal;
