import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';
import axios from 'axios';

const initialState = {
    token: null,
    expire: null,
    error: null,
    roles: null,
    loading: false,
    user_name: null,
    user_id: null,
};

const authStart = ( state, action ) => {
    return updateObject( state, { error: null, loading: true } );
};

const authSuccess = ( state, action ) => {
    return updateObject( state, {
        token: action.idToken,
        expire: action.expire,
        error: null,
        roles: action.roles,
        user_name: action.user_name,
        user_id: action.user_id,
        loading: false
    });
};

const authFail = ( state, action ) => {
    return updateObject( state, {
        error: action.error,
        loading: false
    });
}

const authLogout = ( state, action ) => {
    let token = 'Bearer '+localStorage.getItem( 'access_token' );
    const instance = axios.create({
        baseURL: process.env.REACT_APP_API_ENDPOINT,
        headers: {
            'Authorization': token
        }
    });
    instance.get( 'logout/' )
    .then( res => {
        localStorage.removeItem( 'expires_at' );
        localStorage.removeItem( 'access_token' );
        localStorage.removeItem( 'roles' );
        localStorage.removeItem( 'user_name' );
        localStorage.removeItem( 'user_id' );
    })
    .catch( err => {
        process.env.NODE_ENV !== 'production' && console.log( err );
    });
    return updateObject( state, { token:null, roles: null, expire:null, loading:false, user_name:null, user_id:null } )
};


const reducer = ( state = initialState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTH_START: return authStart(state, action);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default:
            return state;
    }
};

export default reducer;
