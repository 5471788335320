import React, { Component } from 'react';
import Table from "../../components/Table/Table";
import { instance } from '../getInstanceAxios.js';
import Spinner from '../../components/Spinner/Spinner'
import AddModal  from './AddModal';
import UpdateModal  from './UpdateModal';
import { handleAllCheckBoxGeneric, handleCheckBoxGeneric, handleChangePageGeneric } from '../TableFunctions.js';

class Role extends Component {

    state = {
        data: [],
        markedPermission: [],
        permission: [],
        error: null,
        isLoaded: false,
        name: null,
        openAdd: false,
        openUpdate: false,
        errorModal: null,
        idRole: null,
        pagination: {},
        cleanSelection: false,
        selected: [],
        selectAll: false,
    }


    componentDidMount() {
        this.getRoles();
    }

    getRoles = ( page=1 ) => {
        instance.get('role?page='+page)
        .then( res => {
            const { total, current_page, per_page, data } = res.data;
            var genericData = data.map( item => {
                return { first: item.id, second: item.name };
            });
            this.setState({ data:genericData, pagination: { total, currentPage: current_page, rowsPerPage: per_page }, isLoaded:true });
        })
        .catch( err => {
            this.setState({ error:err, isLoaded:true });
        });
    }

    handleChange = event => {
        this.setState({
            [ event.target.id ]: event.target.value
        });
    }

    onUpdate = ( idArray ) => {
        const promises = [];
        promises.push( this.getPermission() );
        promises.push( this.getPermissionId( idArray[0] ) );
        Promise.all( promises ).then( res => {
            for (let i = 0; i < this.state.data.length; i++) {
                if ((this.state.data[i].first)===idArray[0]) {
                    this.setState({ name: this.state.data[i].second,
                        idRole: idArray[0]
                    },  () => { this.setState({cleanSelection: false, openUpdate: true, errorModal:null })});
                    return;
                }
            }
        });
    }

    /**
        Metodo a cargo de traer los permisos asociados a el rol
    **/
    getPermissionId = (id) => {
        instance.get('/role/'+ id +'/permission')
        .then( res => {
            this.setState({ markedPermission:res.data });
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }

    /**
        Metodo a cargo de actualizar el rol con sus permisos asociados
    **/
    handleSubmitUpdate = ( event ) => {
        event.preventDefault();
        const markedPermission = [];

        for (let i = 0; i < this.state.markedPermission.length; i++) {
            markedPermission[i] = this.state.markedPermission[i].id;
        }

        const userData = {
            name: this.state.name,
            markedPermission: markedPermission
        };

        const path = 'role/'+ this.state.idRole;
        instance.put(path, userData)
            .then( res => {
                this.setState({
                    markedPermission:[],
                    idUser: null,
                    openUpdate:null,
                    name:null,
                    cleanSelection: true,
                    errorModal:"Rol modificado correctamente",
                    selected: [],
                    selectAll: false,
                });
                this.getRoles();
            })
            .catch( err => {
                this.setState({ error:err, openUpdate:null });
        });
    }

    /**
        Metodo a cargo de crear el rol con sus permisos asociados
    **/
    handleSubmitAdd = ( event ) => {
        event.preventDefault();
        const markedPermission = [];

        for (let i = 0; i < this.state.markedPermission.length; i++) {
            markedPermission[i] = this.state.markedPermission[i].id;
        }

        const roleData = {
            name: this.state.name,
            markedPermission: markedPermission
        };

        instance.post('role/', roleData)
            .then( res => {
                this.setState({
                    errorModal:"Rol creado correctamente",
                    openAdd:null,
                    name:null,
                    selected: [],
                    selectAll: false,
                });
                this.getRoles();
            })
            .catch( err => {
                this.setState({ errorModal:err });
        });
    }

    /**
        Metodo a cargo de obtener todos los permisos
    **/
    getPermission = () => {
        instance.get('permission/')
        .then( res => {
            this.setState({ permission: res.data });
        })
        .catch( err => {
            this.setState({ error:err });
        });
    }

    /**
        Metodo a cargo de cerrar el modal
    **/
    handleClose = () => {
        this.setState({
            markedRoles:[],
            markedPermission:[],
            errorModal:null,
            openUpdate: false,
            openAdd: false,
            name:null
        });
    }

    /**
        Metodo a cargo de sacar o poner un permiso cuando lo selecciono o lo deselecciono
    **/
    handleChangeSelectPermission = permission => {
        const indexPermission = this.customIndexOf( permission, this.state.markedPermission );
        let actorPermission;
        let sourcePermission;
        this.state.permission.forEach( p => {
            if ( p.name === "actor.read" )
                actorPermission = p;
        });
        this.state.permission.forEach( p => {
            if ( p.name === "source.read" )
                sourcePermission = p;
        });
        const existActorPermission = this.customIndexOf( actorPermission, this.state.markedPermission ) !== -1;
        const existSourcePermission = this.customIndexOf( sourcePermission, this.state.markedPermission ) !== -1;
        if ( indexPermission === -1 ) {
            let newData;
            if (permission.name.includes( "notice" )){
                if (!existSourcePermission && !existActorPermission) {
                    newData = [...this.state.markedPermission, permission, sourcePermission, actorPermission];
                    alert( "source.read y actor.read son necesarios para este permiso" );
                } else {
                    if (!existSourcePermission) {
                        newData = [...this.state.markedPermission, permission, sourcePermission];
                        alert( "source.read es necesario para este permiso" );
                    } else {
                        if ((!existActorPermission)) {
                            newData = [...this.state.markedPermission, permission, actorPermission];
                            alert( "actor.read es necesario para este permiso" );
                        } else {
                            newData = [...this.state.markedPermission, permission];
                        }
                    }
                }
            } else {
                newData = [...this.state.markedPermission, permission];
            }
            this.setState({ markedPermission: newData });

        } else {
            const newData = [...this.state.markedPermission];
            newData.splice( indexPermission, 1 );
            this.setState({ markedPermission: newData });
        }
    }

    /**
        Metodo a cargo de indicar el indice de un elemento en un arreglo
    **/
    customIndexOf = ( item, array ) => {
        for ( let i=0; i<array.length; i++ ) {
            if ( item.id === array[i].id )
                return i;
        }
        return -1;
    }

    /**
        Metodo encargado de activar el modal de Agregar
     **/
    onAddItem = () => {
        this.getPermission();
        this.setState({ openAdd: true, errorModal:null });
    }

    /**
        Metodo encargado de eliminar un elemento o muchos en el backend
     **/
    onDelete = ( idArray ) => {
        for (var i = 0; i < idArray.length; i++) {
            instance.delete('role/'+ idArray[i])
                .then( res => {
                    this.setState({ selected: [], selectAll: false, errorModal:"Rol/es eliminado/s correctamente" });
                    this.getRoles();
                })
                .catch( err => {
                    process.env.NODE_ENV !== 'production' && console.log( err );
                });
        }
    }

    /**
        Metodo encargado de eliminar un elemento o muchos, en el frontend
     **/
    deleteData = ( users ) => {
        const newData = [...this.state.data];
        for (var i = 0; i < users.length; i++) {
            for (var j = 0; j < newData.length; j++) {
                if (newData[j].first===users[i]) {
                    newData.splice(j, 1);
                }
            }
        }
        this.setState({ data: newData });
    }

    handleAllCheckBox = event => {
        let result = handleAllCheckBoxGeneric( event, this.state.data );
        this.setState({ selectAll:result.selectAll, selected:result.selected })
    };

    handleCheckBox = ( event, id) => {
        let result = handleCheckBoxGeneric( event, id, this.state.selected );
        this.setState({ selectAll: false, selected: result.selected });
    };

    handleChangePage = (event, page) => {
        handleChangePageGeneric(event, page, this.getRoles )
    };

    isSelected = id => this.state.selected.indexOf( id ) !== -1;


    render () {
        const title = "Roles";
        const { error,
                data,
                name,
                permission,
                isLoaded,
                openAdd,
                errorModal,
                openUpdate,
                markedPermission,
                pagination,
                cleanSelection
              } = this.state;
        const roles = this.props.roles;
        const rows = [{ id: 'name', numeric: false, disablePadding: true, label: 'Nombre' },
                        { id: '', numeric: false, disablePadding: true, label: '' },
                        { id: '', numeric: false, disablePadding: false, label: '' },
                        { id: '', numeric: false, disablePadding: false, label: '' },
                        { id: '', numeric: false, disablePadding: false, label: '' }];


        if (!isLoaded) {
            return <div> <Spinner /></div>
        } else if(error){
            return <div><h1>{error.message}</h1></div>
        } else {
            return <div>
                <Table
                    viewTable= {true}
                    title={title}
                    rows={rows}
                    data={data}
                    pagination={pagination}
                    errorModal={errorModal}
                    cleanSelection={cleanSelection}
                    onChangePage={this.getRoles}
                    onDelete= { this.onDelete }
                    onAddItem= { this.onAddItem }
                    onUpdate={ this.onUpdate }
                    canCreate={roles.includes('userAdministration')}
                    canDelete={roles.includes('userAdministration')}
                    handleAllCheckBox={this.handleAllCheckBox}
                    handleCheckBox={this.handleCheckBox}
                    handleChangePage={this.handleChangePage}
                    isSelected={this.isSelected}
                    selected={this.state.selected}
                    selectAll={this.state.selectAll}
                />
                {openAdd &&
                    <AddModal
                        handleChange={this.handleChange}
                        handleClose={this.handleClose}
                        handleSubmitAdd={this.handleSubmitAdd}
                        name={name}
                        errorModal={errorModal}
                        handleChangeSelectPermission={this.handleChangeSelectPermission}
                        permission={permission}
                        markedPermission={markedPermission}
                        customIndexOf={this.customIndexOf}
                    />
                }
                {openUpdate &&
                    <UpdateModal
                        errorModal={errorModal}
                        handleClose={this.handleClose}
                        handleChange={this.handleChange}
                        name={name}
                        handleChangeSelectPermission={this.handleChangeSelectPermission}
                        permission={permission}
                        handleSubmitUpdate={this.handleSubmitUpdate}
                        markedPermission={markedPermission}
                        customIndexOf={this.customIndexOf}
                    />
                }
            </div>
        }
    }
}

export default Role;
