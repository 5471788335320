import React, { Fragment } from 'react';
import { Typography, IconButton, Tooltip, FilterListIcon, Fab, AddIcon }
    from '../../containers/materialUi.js';
import SearchBar from 'material-ui-search-bar'


export const addItem = ( classes, onAddItem ) => {
    return(
        <div style={{margin: "20px"}}>
            <Fab style={{ backgroundColor: '#605a73'}}
                size="small"
                color='primary'
                aria-label="Agregar"
                title="Agregar"
                className={classes.fab}
                onClick={ onAddItem }>
                <AddIcon />
            </Fab>
        </div>
    );
};

export const searchItem = ( classes, onSearch, value ) => {
    return (
        <SearchBar
            placeholder="Buscar..."
            value={ value }
            onChange={ ( value ) => onSearch( value ) }
            onCancelSearch={ ( )=> onSearch( "" ) }
            style={{
                marginLeft: '50px',
                maxWidth: 1200,
            }}
        />

    );
}


export const showTitle = ( classes, title ) => {
    return(
        <div style={{ margin:"5px"}} >
            <div className={classes.title}>
                <Typography variant="h5" id="tableTitle">
                    {title}
                </Typography>
            </div>
        </div>
    );
};


export const filterItem = ( onFilter, messageFilter ) => {
    if ( "El filtro es: " === messageFilter){
        messageFilter = "Filtrar"
    }
    return(
        <div style={{ margin:"5px"}} >
            <Fragment>
                <Tooltip title={messageFilter} style={{ backgroundColor: '#605a73'}} onClick={ () => onFilter() } >
                     <IconButton aria-label="Filtrar">
                         <FilterListIcon style={{color:'#ffff'}}/>
                     </IconButton>
                 </Tooltip>
            </Fragment>
        </div>
    );
};

export const editItem = ( onUpdate, checked ) => {
    return(
        <div style={{ margin:"5px"}} >
            <Tooltip title="Ver" onClick={ () => onUpdate( checked )}>
                <IconButton aria-label="Edit">
                    <svg width="24px" height="24px" viewBox="0 0 24 24"><path fill="#000000" d="M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9M12,4.5C17,4.5 21.27,7.61 23,12C21.27,16.39 17,19.5 12,19.5C7,19.5 2.73,16.39 1,12C2.73,7.61 7,4.5 12,4.5M3.18,12C4.83,15.36 8.24,17.5 12,17.5C15.76,17.5 19.17,15.36 20.82,12C19.17,8.64 15.76,6.5 12,6.5C8.24,6.5 4.83,8.64 3.18,12Z" /></svg>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const numItemSelected = ( classes, numSelected ) =>{
    return(
        <div style={{ margin:"5px"}} >
            <div style={{ marginRight:"10px"}} >
                <div className={classes.title}>
                    {numSelected} seleccionado/s
                </div>
            </div>
        </div>
    );
};

export const deleteItem = ( onDelete, checked ) =>{
    return(
        <div style={{ margin:"5px"}} >
            <Tooltip title="Eliminar" onClick={ () => window.confirm( "Queres eliminar estos elementos?" ) ? onDelete( checked ) : false }>
                <IconButton aria-label="Eliminar">
                    <svg width="24px" height="24px" viewBox="0 0 24 24"><path fill="#000000" d="M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19M8,9H16V19H8V9M15.5,4L14.5,3H9.5L8.5,4H5V6H19V4H15.5Z" /></svg>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const fixItem = ( onFixActor, checked, selectAll ) =>{
    return(
        <div style={{ margin:"5px"}} >
            <Tooltip
                title="Corregir"
                style={{textAlign: 'right'}}
                onClick={() => {
                    if (selectAll) {
                        alert("Esta seguro que quiere cambiar todos los actores seleccionados?");
                        onFixActor( );
                    }else {
                        onFixActor( checked );
                    }
                }}>
                <IconButton aria-label="Corregir">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path fill="#000000" d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" />
                    </svg>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const exportItem = ( onExportTxt, numSelected, checked, selectAll ) =>{
    return(
        <div style={{ margin:"5px"}} >
            <Tooltip title="Exportar" onClick={() => { numSelected>4000 ?
                    alert("Excedio el limite de 4000 noticias para exportar") : (selectAll ? onExportTxt() : onExportTxt(checked)) }}>
                <IconButton aria-label="Exportar">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path fill="#000000" d="M6,2C4.89,2 4,2.9 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2M13,3.5L18.5,9H13M8.93,12.22H16V19.29L13.88,17.17L11.05,20L8.22,17.17L11.05,14.35" />
                    </svg>
                </IconButton>
            </Tooltip>
        </div>
    );
};

export const tagItem = ( onTag, numSelected, checked, selectAll ) =>{
    return(
        <div style={{ margin:"5px"}} >
            <Tooltip title="Taguear" onClick={() => { numSelected>4000 ?
                    alert("Excedio el limite de 4000 noticias para taguear") : ( selectAll ? onTag( [], true ) : onTag( checked, true ) ) }}>
                <IconButton aria-label="Taguear">
                    <svg width="24px" height="24px" viewBox="0 0 24 24">
                        <path fill="000000" d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
                    </svg>
                </IconButton>
            </Tooltip>
        </div>
    );
};
